import React, {useState} from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Text,
  ReferenceLine,
  LabelList,
  TooltipProps,
} from 'recharts'
import {InvestmentChartDataProps} from '../_model'
import {
  downloadChart,
  toAbbreviatedNumber,
  toUSD,
  interpolateColors,
} from '../../../../helper/charts.helper'
import useScreenWidth from '../../../../hooks/useScreenWidth'
import {scaleSqrt, scaleLinear, scaleLog} from 'd3-scale'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'

// Custom Tooltip component
const CustomTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  if (active && payload && payload.length && payload[0]?.payload?.label !== 'Custom') {
    const data = payload[0].payload

    const gain = data.futureValue - data.contributions - data.initialDeposit
    const absoluteGain = Math.abs(gain)
    return (
      <div className='custom-tooltip'>
        <p className='paragraph-small label-main'>{data.label}</p>
        {data.isLoss ? (
          <p className='paragraph-x-small mb-0 label-loss'>
            Loss: <span className='label-bold'>{toUSD(absoluteGain)}</span>
          </p>
        ) : (
          <p className='paragraph-x-small mb-0 label-gain'>
            Gains: <span className='label-bold'>{toUSD(absoluteGain)}</span>
          </p>
        )}
        <p className='paragraph-x-small mb-0 label-contributions'>
          Contributions: <span className='label-bold'>{toUSD(data.contributions)}</span>
        </p>
        <p className='paragraph-x-small mb-0 label-initial-deposit'>
          Starting amount: <span className='label-bold'>{toUSD(data.initialDeposit)}</span>
        </p>
      </div>
    )
  }
  return null
}

const ProjectionsChart: React.FC<InvestmentChartDataProps> = (props) => {
  const screenWidth = useScreenWidth()

  const {
    data,
    handleCustomBar,
    svgWidth,
    setSvgWidth,
    svgNumTriangles,
    xAxisOffset,
    setAxisOffset,
  } = props

  //dynamically setting the angle, padding and width of chart acc to screen sizes
  const labelAngle = screenWidth < 410 ? -30 : 0
  const labelPadding = screenWidth < 410 ? 8 : 0
  const chartPadding =
    screenWidth < 546
      ? {top: 60, bottom: 40, right: 5, left: 0}
      : {top: 90, bottom: 50, right: 40, left: 10}
  const _chartHeight = screenWidth > 767 ? 400 : screenWidth > 567 ? 250 : 220

  //custom top label of the bars
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, index, width} = props
    var futureValue
    var gain
    //in-case want to show full amount on the bars, use toUSD function here instead of to abbreviated.
    if (index + 1 !== data.length) {
      futureValue = Math.abs(
        data[index]?.futureValue // - data[0]?.contributions - data[0]?.initialDeposit
      ).toFixed(2)
      gain = toAbbreviatedNumber(parseFloat(futureValue)) // futureValue = toUSD(props.value)
      gain = '$' + gain // remove this
    } else {
      gain = '$xx.00'
    }
    return (
      <g>
        <filter id='dropShadow'>
          <feDropShadow dx='0' dy='2' stdDeviation='2' floodColor='rgba(0,0,0,0.3)' />
        </filter>

        <svg
          x={x + width / 2 - 50}
          y={y - (screenWidth > 567 ? 85 : 57)}
          width='98'
          height={screenWidth > 567 ? '55' : '30'}
          viewBox='0 0 98 55'
          fill='none'
          filter='url(#dropShadow)'
        >
          <g clip-path='url(#clip0_14_7)'>
            <path d='M49 55L79.3109 31.75H18.6891L49 55Z' fill='white' />
            <rect width='98' height='48' rx='24' fill='white' />
          </g>
          <defs>
            <clipPath id='clip0_14_7'>
              <rect width='98' height='55' fill='white' />
            </clipPath>
          </defs>
        </svg>

        <Text
          x={x + width / 2}
          y={y - (screenWidth > 567 ? 55 : 40)}
          textAnchor='middle'
          className='custom-label'
          fill={data[index].labelColor}
          angle={labelAngle}
        >
          {gain}
        </Text>
      </g>
    )
  }

  //custom bottom labels of the chart
  const CustomizedAxisTick = (props: any) => {
    const {x, y, payload, index} = props
    return (
      <g>
        {svgWidth < 5 ? null : (
          <svg y={y - 15} x={x - 45}>
            <rect width={svgWidth - 30} height={15} rx='10' ry='10' fill='rgba(0, 0, 0, 0.12)' />
          </svg>
        )}
        <Text
          x={x}
          y={y + labelPadding}
          width={100}
          textAnchor='middle'
          verticalAnchor='start'
          angle={labelAngle}
          className='custom-tick-label'
        >
          {payload.value}
        </Text>
        <Text
          x={x}
          y={y + 30}
          width={100}
          textAnchor='middle'
          verticalAnchor='start'
          className='custom-label'
          // angle={CompoundingPercentageAngle}
          fill={data[index].labelColor}
        >
          {data[index].customLabel}
        </Text>
      </g>
    )
  }

  // Function to handle click on custom bar
  const onClickCustomBar = (props: any) => {
    props.label === 'Custom' && handleCustomBar()
  }

  const referenceLines = [
    {
      value: data[0]?.initialDeposit,
      label: `$${toAbbreviatedNumber(data[0]?.initialDeposit)}`,
    },
    {
      value: data[0]?.contributions + data[0]?.initialDeposit,
      label: `$${toAbbreviatedNumber(data[0]?.contributions + data[0]?.initialDeposit)}`,
    },
  ]

  //calculating the difference for domain and scale
  const fifthBarHeight = data.length >= 5 ? data[4].futureValue : 0
  const firstBarHeight = data.length >= 1 ? data[0].futureValue : 0
  const heightDifference = fifthBarHeight - firstBarHeight

  //getting max value for domain
  const maxYValue = Math.max(
    ...data.map(
      (entry) => entry.futureValueWithoutContributions + entry.contributions + entry.initialDeposit
    )
  )

  const first_threshold = 100000
  const second_threshold = 10000000

  const domainY = (): [number, number] => {
    //getting min value for domain because when diff increases the lower one is not visible on screen
    const minDepositOrContribution = Math.min(data[0]?.initialDeposit, data[0]?.contributions)

    //changing scale dynamically when height diff increases
    if (heightDifference <= first_threshold) {
      return [0, maxYValue]
    } else {
      return [minDepositOrContribution / 2, maxYValue]
    }
  }

  const yAxisDomain: [number, number] = domainY()

  //changing scale dynamically: at start, it's simple linear
  //if height diff increases than second_threshold and it's lesser than initial deposit: sqrt scale
  //for the very very huge diff: log scale
  const scaleY =
    heightDifference <= first_threshold
      ? scaleLinear().domain(yAxisDomain)
      : heightDifference <= second_threshold && first_threshold < data[0].initialDeposit
      ? scaleSqrt().domain(yAxisDomain)
      : scaleLog().domain(yAxisDomain)

  const PlantImage = (props: any) => (
    <svg height={props.height} x={props.x} y={props.y} filter='url(#overlapFilter)'>
      <defs>
        <filter id='overlapFilter'>
          <feDropShadow dx='0' dy='2' stdDeviation='2' floodColor='rgba(0, 0, 0, 0.3)' />
        </filter>
      </defs>
      <path d={props.d} fill={props.fill} />
    </svg>
  )

  const StemImage = (props: any) => (
    <g>
      <svg height={props.height} x={props.x} y={props.y}>
        <rect width={props.width} height={props.height} fill='#A98469' />
      </svg>
    </g>
  )

  const renderCustomStem = (props: any) => {
    const {x, y, height, width} = props
    //  setStemHeight(height)
    const maxStemWidth = screenWidth < 567 ? 8 : 15
    const xOffset = (width - maxStemWidth) / 2

    return (
      <g>
        <StemImage height={height} x={x + xOffset} y={y} width={maxStemWidth} />
      </g>
    )
  }

  const renderSVG = (props: any) => {
    const {x, y, height, width, futureValue, contributions} = props
    svgWidth === 0 && setSvgWidth(width)
    let tempHeight = height
    let numTriangles = Math.ceil(tempHeight / (screenWidth > 567 ? svgNumTriangles : 30))
    let gainPercentage = contributions / futureValue

    return (
      <g>
        {Array.from({length: numTriangles}).map((_, index) => {
          //let fillColor = index / numTriangles >= gainPercentage ? '#93C47D' : '#6EA753'
          let scaledGain = (gainPercentage - 0.3) / 0.75
          let fillColor = interpolateColors('#228B22', '#A2242F', scaledGain)
          // Math.min(gainPercentage, 1)

          const triangleWidth =
            (index > 0 ? (screenWidth > 567 ? 25 : 20) : numTriangles > 2 ? 15 : 15) +
            (svgWidth - (svgWidth / numTriangles) * index)
          const xOffset = (svgWidth - triangleWidth) / 2

          return (
            <PlantImage
              x={x + xOffset + xAxisOffset}
              key={index}
              d={`M${triangleWidth / 2} 0L${triangleWidth} ${screenWidth > 567 ? 45 : 30}H0Z`}
              fill={fillColor}
              height={tempHeight}
              y={
                y -
                (numTriangles > 1 ? (screenWidth > 567 ? 45 : 30) : height) +
                tempHeight -
                (screenWidth > 567 ? 35 : 20) * index
              }
            />
          )
        })}
      </g>
    )
  }

  return (
    <div
      style={{
        width: '100%',
      }}
      className='box-shadow'
      id='bar-chart'
    >
      <div className='d-md-none d-flex ps-sm-10 pt-sm-10 ps-6 pt-6 position-absolute z-index-1'>
        {/* download button for xs screens */}
        <div className='download-btn-container d-block d-sm-none'>
          <img
            alt='download'
            className='download-btn-img'
            onClick={downloadChart}
            src={toAbsoluteUrl('/media/home/download-icon.svg')}
          />
        </div>
      </div>
      <ResponsiveContainer width='100%' height={_chartHeight} id='chart-container'>
        <BarChart data={data} margin={chartPadding}>
          {referenceLines.map((line, index) => (
            <ReferenceLine
              key={index}
              y={line.value}
              yAxisId='left'
              stroke='#CCCCCC'
              strokeDasharray='2 5'
              textAnchor='bottom'
              label={{
                dy: index === 0 ? 4 : -4,
                position: 'left',
                value: line.label,
                className: 'custom-tick-label',
              }}
              className='custom-tick-label reference-line-label'
            />
          ))}

          <XAxis
            dataKey='label'
            axisLine={false}
            tickLine={false}
            interval={0}
            minTickGap={5}
            tick={<CustomizedAxisTick />}
          />
          <YAxis
            yAxisId='left'
            scale={scaleY}
            domain={domainY}
            tick={false}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip contentStyle={{backgroundColor: 'white'}} content={<CustomTooltip />} />
          <Bar
            dataKey='initialDeposit'
            stackId='assets'
            className='initial-deposit-bar'
            yAxisId='left'
            onClick={onClickCustomBar}
            shape={renderCustomStem}
          />
          <Bar
            dataKey='futureValue'
            yAxisId='left'
            stackId='assets'
            onClick={onClickCustomBar}
            shape={renderSVG}
          >
            <LabelList content={<CustomizedBarTopLabel />} position='top' />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
export default ProjectionsChart
