import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import CustomInputField from '../../../../../components/customInputField/CustomInputField'
import {customAssets} from './Pictograph.config'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

interface IPictographModalProps {
  show: boolean
  onHide: () => void
  handleCustomAssets: (name: string, price: number, image: string) => void
}

// formik schema
const CustomPictographSchema = Yup.object().shape({
  assetName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(25, 'Maximum 25 characters')
    .required('Asset name is required'),
  assetPrice: Yup.number()
    .min(1, 'Price cannot be less than $1')
    .max(1000000, 'Price cannot be greater than 1,000,000')
    .required('Price is required'),
  assetImage: Yup.string().required('Image is required'),
})

// formik initial value
const initialValues = {
  assetName: '',
  assetPrice: 0,
  assetImage: '',
}

const CustomPictographModal: React.FC<IPictographModalProps> = (props) => {
  const [selectedImageId, setSelectedImageId] = useState<number | null>()

  const formik = useFormik({
    initialValues,
    validationSchema: CustomPictographSchema,
    onSubmit: async (values, {resetForm}) => {
      props.handleCustomAssets(values.assetName, values.assetPrice, values.assetImage)
      resetForm({})
      setSelectedImageId(null)
    },
  })

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter' className='heading sub-heading-x-small'>
          Add a custom item
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* custom bar form */}
        <form onSubmit={formik.handleSubmit} noValidate className='form' id='customBar-form'>
          <div className='row m-0'>
            <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>
                  Item name<span className='text-danger'>*</span>
                </p>
              </div>
              <div
                className={`input-container d-flex justify-content-center align-items-center ${
                  formik.touched.assetName && formik.errors.assetName ? 'error-border' : ''
                }`}
              >
                <input
                  id='asset-name'
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=' e.g. IPhone X'
                  {...formik.getFieldProps('assetName')}
                />
              </div>

              {formik.touched.assetName && formik.errors.assetName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.assetName}</div>
                </div>
              )}
            </div>
            <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>
                  Item price<span className='text-danger'>*</span>
                </p>
              </div>
              <div
                className={`input-container d-flex justify-content-center align-items-center ${
                  formik.touched.assetPrice && formik.errors.assetPrice ? 'error-border' : ''
                }`}
              >
                <CustomInputField
                  id='asset-price'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=' e.g 1000.00'
                  decimalsLimit={2}
                  maxLength={8}
                  onValueChange={(value: any) => {
                    const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                    formik.setFieldValue('assetPrice', parsedValue)
                  }}
                  value={formik.values.assetPrice}
                />
              </div>
            </div>
          </div>
          {/* custom assets */}
          <p className='m-0 mt-5 fields-label'>
            Select an icon<span className='text-danger'>*</span>
          </p>
          <div className='d-flex flex-wrap justify-content-center align-items-center'>
            {customAssets.map((asset) => (
              <div className='position-relative'>
                <img
                  key={asset.id}
                  src={asset.image}
                  alt={asset.image}
                  className={`pictograph-custom-image mx-1 p-2 ${
                    selectedImageId === asset.id ? 'selected-image-bg-color' : null
                  }`}
                  onClick={() => {
                    formik.setFieldValue('assetImage', asset.svg)
                    setSelectedImageId(asset.id)
                  }}
                />
                {/* selected image tick-circle */}
                <img
                  alt='selectd-tick'
                  src={toAbsoluteUrl('/media/icons/duotune/arrows/tick-circle.svg')}
                  className={`${
                    selectedImageId === asset.id
                      ? 'selected-image-tick position-absolute'
                      : 'd-none'
                  }`}
                />
              </div>
            ))}
          </div>
          <Modal.Footer className='border-0 p-0'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              onClick={props.onHide}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Add a custom item
            </button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CustomPictographModal
