import {useEffect, useState, useRef, memo, Fragment} from 'react'
import {INPUT_BOX_TOOLTIPS, TYPES_OF_ROR} from '../../../../constants/AppConstants'
import Sliders from './components/projectionChart/components/Sliders'
import D3Sliders from './components/projectionChart/components/D3Sliders'
import ProjectionsChart from './components/projectionChart/ProjectionsChart'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import ProjectionsChart2 from './components/projectionChart/PeojectionsChart2'
import ProjectionsChart3 from './components/projectionChart/ProjectionsChart3'
import CustomOverlayTooltip from './components/CustomOverlayTooltip'
import {useProjectionsState} from './components/ProjectionsUtils'
import {
  assets,
  contributionAndDividendsFrequency,
  defaultAsset,
} from './components/ProjectionsConfig'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import {useAdvisorFormContext} from '../../../context/AdvisorContext'
import CustomInputField from '../../../../components/customInputField/CustomInputField'
import ProjectionSlider from '../../../../components/projectionSlider/ProjectionSlider'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useAdvisorsState} from '../../advisor/components/AdvisorsUtils'
import useScreenWidth from '../../hooks/useScreenWidth'
import D3Chart from './components/projectionChart/D3Chart'

type ProjectionsSectionProps = {
  refProp: React.RefObject<HTMLDivElement>
}

const ProjectionsSection: React.FC<ProjectionsSectionProps> = ({refProp}) => {
  const {pathname} = useLocation()
  const [svgWidth, setSvgWidth] = useState(0)
  const [svgNumTriangles, setSvgNumTriangles] = useState(50)
  const [xAxisOffset, setAxisOffset] = useState(0)

  //d3 chart inputs
  const [triangleHeightDecreaseFactor, setTriangleHeightDecreaseFactor] = useState(0.9)
  const [triangleWidthDecreaseFactor, setTriangleWidthDecreaseFactor] = useState(0.9)
  const [overlapMargin, setOverlapMargin] = useState(15)
  const [minStemHeight, setMinStemHeight] = useState(5)

  // extracting useparams
  const {advisor_url} = useParams()

  const location = useLocation()

  // useNavigation hook
  const navigate = useNavigate()

  const {assetsState} = useAdvisorsState()
  // Check if "arq" is in the URL
  const pathsToCheck = ['arq', 'advisor-demo1', 'advisor-demo2', 'waystone']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))

  // screen width custom hook
  const screenWidth = useScreenWidth()

  // parallax animation stuff
  const targetRef = useRef(null)

  // destructuring useReceiverFormContext hook
  const {setReceiverFormDataObj, receiverFormDataObj} = useReceiverFormContext()

  const {isFormUploading} = useAdvisorFormContext()

  // state hooks
  const [showModal, setShowModal] = useState(false)
  const [isExtraContributions, setIsExtraContributions] = useState(false)
  const [isARQFields, setIsARQFields] = useState(false)

  // destructuring custom(useProjectionsState) state
  const {
    chartData,
    sentence,
    isFirstRender,
    selectedRate,
    handleKeyPress,
    handleCompoundingFreqChange,
    handleARQRentalFreqChange,
    handleAssetChange,
    formik,
    handleReplyButton,
  } = useProjectionsState()

  const filteredArray = assetsState.filter((asset) => {
    return chartData.some((filteredItem) => asset.label === filteredItem.label)
  })

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    const timer = setTimeout(() => {
      setReceiverFormDataObj((prevState) => ({
        ...prevState,
        starting_amount: formik.values.initialDeposit,
        contributions: formik.values.contributions,
        contributions_frequency: formik.values.contributionFrequency,
        selected_rate_of_return_obj:
          assets.find((asset) => asset.id === selectedRate.id) || assets[defaultAsset],
        years_to_grow: formik.values.numberOfYears,
        ARQ_Flip_Gain: formik.values.ARQ_Flip_Gain,
        ARQ_rental_income: formik.values.ARQ_rental_income,
        ARQ_rental_contribution: formik.values.ARQ_rental_contribution,
        ARQ_appreciation: formik.values.ARQ_appreciation,
        ARQ_property_worth: formik.values.ARQ_property_worth,
        management_fee: formik.values.management_fee,
        selected_bars: receiverFormDataObj.selected_bars,
      }))
      formik.handleSubmit()
    }, 100) // Delay for 0.1s
    return () => {
      clearTimeout(timer)
    } // Clear the timeout if the component unmounts or the dependency changes
  }, [formik.values])

  // handle RoR/comppounding growth
  const handleCompoundingGrowth = () => {
    return advisor_url || isArqInURL
      ? filteredArray.map((option, index) => {
          return (
            option.label !== 'Custom' && (
              <option className='px-2' key={`${option.dropdownLabel}-${index}`} value={option.id}>
                {option.dropdownLabel}
              </option>
            )
          )
        })
      : filteredArray
          .filter((item) => {
            return item.type === TYPES_OF_ROR.TYPE_SIMPLE
          })
          .map((option, index) => {
            return (
              <Fragment key={index}>
                {option.label !== 'Custom' && (
                  <option
                    className='px-2'
                    key={`${option.dropdownLabel}-${index}`}
                    value={option.id}
                  >
                    {option.dropdownLabel}
                  </option>
                )}
              </Fragment>
            )
          })
  }

  return (
    <section className='projections-container'>
      <div className='pb-1 px-sm-14 px-7 px-md-15 px-md-0'>
        {/* projections form */}
        <form onSubmit={formik.handleSubmit} noValidate className='form' id='projection-form'>
          <div className='row m-0'>
            {/* Starting Amount field */}
            <div className='col-12 col-md-3 col-lg-2 fv-row mb-0 mb-lg-0 ps-0'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Starting Amount</p>
                <CustomOverlayTooltip
                  content={INPUT_BOX_TOOLTIPS.STARTING_AMOUNT}
                  className='tooltip-logo'
                />
              </div>
              <div
                className={`input-container d-flex justify-content-center align-items-center ${
                  formik.touched.initialDeposit && formik.errors.initialDeposit
                    ? 'error-border'
                    : ''
                }`}
              >
                <CustomInputField
                  id='initial-deposit'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=' e.g 1000.00'
                  decimalsLimit={2}
                  maxLength={8}
                  defaultValue={formik.values.initialDeposit}
                  onValueChange={(value: any) => {
                    const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                    formik.setFieldValue('initialDeposit', parsedValue)
                  }}
                  onKeyDown={handleKeyPress}
                  value={formik.values.initialDeposit}
                />
              </div>
              <ProjectionSlider
                value={formik.values.initialDeposit}
                maxValue={100000}
                onRangeChange={(value: any) =>
                  formik.setFieldValue('initialDeposit', parseInt(value!))
                }
              />
            </div>
            {/* Rate of Return/compounding growth dropdown */}
            <div className='col-5 col-sm-5 col-md-4 col-lg-3 fv-row projections-select mb-0 mb-lg-0 ps-0 pe-0'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Rate of Return</p>
                <CustomOverlayTooltip
                  content={INPUT_BOX_TOOLTIPS.RATE_OF_RETURN}
                  className='tooltip-logo'
                />
              </div>
              <select
                id='compounding-growth'
                className='form-select dropdown'
                {...formik.getFieldProps(`compoundingGrowth`)}
                onChange={handleAssetChange}
              >
                {handleCompoundingGrowth()}
              </select>
            </div>
            {/* Years to Grow field */}
            <div className='d-none d-md-block col-5 col-sm-5 col-md-4 col-lg-3 fv-row mb-0 mb-lg-0 pe-0'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Years to Grow</p>
                <CustomOverlayTooltip
                  content={INPUT_BOX_TOOLTIPS.YEARS_TO_GROW}
                  className='tooltip-logo'
                />
              </div>
              <div
                className={`input-container d-flex justify-content-center align-items-center ${
                  formik.touched.numberOfYears && formik.errors.numberOfYears ? 'error-border' : ''
                }`}
              >
                <p className='m-0 currency-symbol d-none'>$</p>
                <input
                  id='number-of-years'
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='e.g 10'
                  {...formik.getFieldProps('numberOfYears')}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <ProjectionSlider
                value={formik.values.numberOfYears}
                minValue={1}
                maxValue={20}
                isMoney={false}
                onRangeChange={(value: any) =>
                  formik.setFieldValue('numberOfYears', parseInt(value!))
                }
              />
            </div>
            {/* Years to Grow field(mobile) */}
            <div className='row p-0 m-0 d-block d-md-none'>
              <div className='col-5 col-sm-5 col-md-4 col-lg-3 fv-row mb-0 mb-lg-0 pe-0 p-0 mt-2'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Years to Grow</p>
                  <CustomOverlayTooltip
                    content={INPUT_BOX_TOOLTIPS.YEARS_TO_GROW}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.numberOfYears && formik.errors.numberOfYears
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol d-none'>$</p>
                  <input
                    id='number-of-years'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='e.g 10'
                    {...formik.getFieldProps('numberOfYears')}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.numberOfYears}
                  minValue={1}
                  maxValue={20}
                  isMoney={false}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('numberOfYears', parseInt(value!))
                  }
                />
              </div>
            </div>
            {isExtraContributions ? (
              <>
                {/* Contributions field */}
                <div className='col-5 col-md-3 col-lg-2 fv-row mb-0 mb-lg-0 p-0 ps-lg-2'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>Extra Contributions</p>
                    <CustomOverlayTooltip
                      content={INPUT_BOX_TOOLTIPS.CONTRIBUTIONS}
                      className='tooltip-logo'
                    />
                  </div>
                  <div
                    className={`input-container d-flex justify-content-center align-items-center ${
                      formik.touched.contributions && formik.errors.contributions
                        ? 'error-border'
                        : ''
                    }`}
                  >
                    <CustomInputField
                      id='contributions'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=' e.g 10.00'
                      decimalsLimit={2}
                      maxLength={6}
                      defaultValue={formik.values.contributions}
                      onValueChange={(value: any) => {
                        const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                        formik.setFieldValue('contributions', parsedValue)
                      }}
                      onKeyDown={handleKeyPress}
                      value={formik.values.contributions}
                    />
                  </div>
                  <ProjectionSlider
                    value={formik.values.contributions}
                    maxValue={10000}
                    onRangeChange={(value: any) =>
                      formik.setFieldValue('contributions', parseInt(value!))
                    }
                  />
                </div>
                {/* Contributions frequency dropdown */}
                <div className='col-5 col-md-3 col-lg-2 fv-row projections-select mb-0 mb-lg-0 pe-0 pe-md-2'>
                  <p className='ps-1 m-0 fields-label time-frame'>Time Frame</p>
                  <select
                    id='compounding-frequency'
                    className='form-select dropdown'
                    {...formik.getFieldProps('contributionFrequency')}
                    onChange={handleCompoundingFreqChange}
                  >
                    {contributionAndDividendsFrequency.map((option) => {
                      return (
                        <option className='px-2' key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </>
            ) : (
              <>
                <div className='col-12 col-md-4 fv-row mb-0 mb-lg-0 p-0'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-5 m-0 fields-label'>Extra contributions</p>
                    <CustomOverlayTooltip
                      content={INPUT_BOX_TOOLTIPS.EXTRA_CONTRIBUTIONS}
                      className='tooltip-logo'
                    />
                  </div>
                  <div className='d-flex align-items-baseline'>
                    <div
                      className='btn btn-sm btn-icon btn-active-color-primary btn-color-dark ms-3'
                      onClick={() => {
                        setIsExtraContributions(true)
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen035.svg'
                        className='svg-icon-2x'
                      />
                    </div>
                    <p className='teal-text paragraph-small'>Make extra contributions</p>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* error messages */}
          <div className='text-center mt-2'>
            {formik.touched.initialDeposit && formik.errors.initialDeposit ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'> * {formik.errors.initialDeposit}</div>
              </div>
            ) : null}
            {formik.touched.contributions && formik.errors.contributions ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'> * {formik.errors.contributions}</div>
              </div>
            ) : null}
            {formik.touched.numberOfYears && formik.errors.numberOfYears ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'> * {formik.errors.numberOfYears}</div>
              </div>
            ) : null}
          </div>
        </form>

        <div className='col-12 mb-8 mt-md-4'>
          {pathname === '/home/2424184' ? (
            <ProjectionsChart
              svgWidth={svgWidth}
              setSvgWidth={setSvgWidth}
              svgNumTriangles={svgNumTriangles}
              xAxisOffset={xAxisOffset}
              setAxisOffset={setAxisOffset}
              data={chartData}
              handleCustomBar={() => 'setShowThankYouModal(true)'}
            />
          ) : pathname === '/home/3125125' ? (
            <ProjectionsChart2
              svgWidth={svgWidth}
              setSvgWidth={setSvgWidth}
              svgNumTriangles={svgNumTriangles}
              xAxisOffset={xAxisOffset}
              setAxisOffset={setAxisOffset}
              data={chartData}
              handleCustomBar={() => 'setShowThankYouModal(true)'}
            />
          ) : pathname === '/home/4236236' ? (
            <D3Chart
              data={chartData}
              triangleHeightDecreaseFactor={triangleHeightDecreaseFactor}
              triangleWidthDecreaseFactor={triangleWidthDecreaseFactor}
              overlapMargin={overlapMargin}
              minStemHeight={minStemHeight}
            />
          ) : null}
        </div>
      </div>

      {/* Debug Sliders for projection chart  */}
      {pathname === '/home/2424184' ? (
        <Sliders
          svgWidth={svgWidth}
          setSvgWidth={setSvgWidth}
          svgNumTriangles={svgNumTriangles}
          setSvgNumTriangles={setSvgNumTriangles}
          xAxisOffset={xAxisOffset}
          setAxisOffset={setAxisOffset}
        />
      ) : pathname === '/home/4236236' ? (
        <D3Sliders
          triangleHeightDecreaseFactor={triangleHeightDecreaseFactor}
          setTriangleHeightDecreaseFactor={setTriangleHeightDecreaseFactor}
          triangleWidthDecreaseFactor={triangleWidthDecreaseFactor}
          setTriangleWidthDecreaseFactor={setTriangleWidthDecreaseFactor}
          overlapMargin={overlapMargin}
          setOverlapMargin={setOverlapMargin}
          minStemHeight={minStemHeight}
          setMinStemHeight={setMinStemHeight}
        />
      ) : null}
    </section>
  )
}

export default memo(ProjectionsSection)
