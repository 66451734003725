import {useRef} from 'react'
import {HERO_SECTION} from '../../../constants/AppConstants'
import {CONTACT_US} from '../../../constants/AppConstants'
import ProjectionsSection from './projections/ProjectionsSection'
import {Route, Routes, Navigate, Outlet} from 'react-router-dom'

type HeroSectionProps = {
  handleClickStayTuned: () => void
  handleClickProjections?: () => void
}
const HeroSection: React.FC<HeroSectionProps> = ({handleClickStayTuned}) => {
  // projections ref
  const ProjectionsSectionRef = useRef<HTMLDivElement>(null)

  return (
    <Routes>
      <Route element={<HeroSectionLayout handleClickStayTuned={handleClickStayTuned} />}>
        <Route path='/2424184' element={<ProjectionsSection refProp={ProjectionsSectionRef} />} />
        <Route path='/3125125' element={<ProjectionsSection refProp={ProjectionsSectionRef} />} />
        <Route path='/4236236' element={<ProjectionsSection refProp={ProjectionsSectionRef} />} />
        <Route index element={<Navigate to='/home/4236236' />} />
      </Route>
    </Routes>
  )
}

export default HeroSection

const HeroSectionLayout: React.FC<HeroSectionProps> = ({handleClickStayTuned}) => {
  const targetRef = useRef(null)

  return (
    <div
      className='content-container hero-background-container mb-20'
      ref={targetRef}
      id='hero-section'
    >
      <div className='hero-container z-index-1'>
        <div className='d-flex flex-wrap justify-content-center '>
          <span className='pe-md-5'>
            <img className='logo-image' src={HERO_SECTION.IMAGES.LOGO_IMAGE} alt='logo' />
          </span>
          <h2 className='d-flex justify-content-center align-items-md-center px-8 px-md-0 pe-md-5 my-md-4 mt-6 mb-4'>
            {HERO_SECTION.VISUAL_INVESTING}
          </h2>
          <h3 className='d-flex align-items-center fw-bold my-md-4 mt-md-6 mb-4'>
            {HERO_SECTION.WORK_IN_PROGRESS}
          </h3>
        </div>
        {/* desktop view text */}
        <p className='d-none d-md-block text-end px-20'>
          {/* {HERO_SECTION.CALCULATOR} */}
          Curious?{' '}
          <span className='fw-bold pink-text cursor-pointer' onClick={handleClickStayTuned}>
            {CONTACT_US.CONTACT}
          </span>
        </p>
        {/* mobile view text */}
        <p className='d-block d-md-none text-center cursor-pointer' onClick={handleClickStayTuned}>
          Curious? <span className='fw-bold pink-text cursor-pointer'>{CONTACT_US.CONTACT}</span>
        </p>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
