import {toPng} from 'html-to-image'
import {toast} from 'react-toastify'

export const roundToLowest = (value: number) => {
  return Math.floor(value)
}

export const toAbbreviatedNumber = (value: number) => {
  if (value < 1e3) return +value.toString()
  if (value >= 1e3 && value < 1e6) return +(value / 1e3).toFixed(1) + 'K'
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(1) + 'M'
  if (value >= 1e9 && value < 1e12) return +(value / 1e9).toFixed(1) + 'B'
  if (value >= 1e12) return +(value / 1e12).toFixed(1) + 'T'
}

export const toUSD = (value: number) => {
  const formattedValue = Number(roundToLowest(value)).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formattedValue
}

export const toUSDWithoutRoundOff = (value: number) => {
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formattedValue.format(value)
}

export const downloadChart = () => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const chartContainer = document.getElementById('chart-container')

  if (chartContainer) {
    toPng(chartContainer, {cacheBust: false})
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'my-image-name.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        notifyError('An error occurred.')
      })
  }
}

export const interpolateColors = (color1: string, color2: string, percent: number): string => {
  console.log('lerp percentage: ' + percent)

  // Convert the hex colors to RGB values
  const r1 = parseInt(color1.substring(1, 3), 16)
  const g1 = parseInt(color1.substring(3, 5), 16)
  const b1 = parseInt(color1.substring(5, 7), 16)

  const r2 = parseInt(color2.substring(1, 3), 16)
  const g2 = parseInt(color2.substring(3, 5), 16)
  const b2 = parseInt(color2.substring(5, 7), 16)

  // Interpolate the RGB values
  const r = Math.round(r1 + (r2 - r1) * percent)
  const g = Math.round(g1 + (g2 - g1) * percent)
  const b = Math.round(b1 + (b2 - b1) * percent)

  // Convert the interpolated RGB values back to a hex color
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}
// ***TSBG chart helper functions***
// formatting future value
export const FVFormatter = (value: any) => {
  const suffixes = ['', 'K', 'M', 'B', 'T']

  // Find the appropriate suffix based on the length of the value
  const suffixIndex = Math.floor(String(value).length / 3)

  // Calculate the value with the appropriate suffix, rounded up to the nearest integer
  const formattedValue = Math.ceil(value / Math.pow(1000, suffixIndex)) + suffixes[suffixIndex]

  return formattedValue
}
