import React from 'react'
import {Slider} from 'rsuite'
import 'rsuite/dist/rsuite-no-reset.min.css'
import {D3SliderProps} from '../../_model'

const D3Sliders: React.FC<D3SliderProps> = (props) => {
  const {
    triangleHeightDecreaseFactor,
    setTriangleHeightDecreaseFactor,
    triangleWidthDecreaseFactor,
    setTriangleWidthDecreaseFactor,
    overlapMargin,
    setOverlapMargin,
    minStemHeight,
    setMinStemHeight,
  } = props
  return (
    <div className='row gap-10 mt-20 px-7 align-content-end flex-wrap'>
      <div className='row'>
        <label className='fw-bold h5'>Debug sliders:</label>
        <label>Triangle Height Decrease Factor:</label>
        <Slider
          barClassName='slider-track'
          min={0}
          max={1}
          step={0.1}
          value={triangleHeightDecreaseFactor}
          onChange={(e: any) => setTriangleHeightDecreaseFactor(e)}
        />
        <label className='pt-3'>Triangle Width Decrease Factor:</label>
        <Slider
          barClassName='slider-track'
          min={0}
          max={1}
          step={0.1}
          value={triangleWidthDecreaseFactor}
          onChange={(e: any) => setTriangleWidthDecreaseFactor(e)}
        />
        <label className='pt-3'>Overlap Margin</label>
        <Slider
          barClassName='slider-track'
          min={0}
          max={30}
          value={overlapMargin}
          onChange={(e: any) => setOverlapMargin(e)}
        />
        <label className='pt-3'>Minimum Stem Height:</label>
        <Slider
          barClassName='slider-track'
          min={0}
          max={30}
          value={minStemHeight}
          onChange={(e: any) => setMinStemHeight(e)}
        />
      </div>
    </div>
  )
}

export default D3Sliders
