import {LIFESTYLE_SECTION, sectionIds} from '../../../constants/AppConstants'

const LifestyleSection = () => {
  return (
    <section className='pt-10 pt-lg-20 position-relative' id={sectionIds[5]}>
      <h1 className='my-2 mb-8 mb-md-14 text-center position-relative z-index-1'>
        {LIFESTYLE_SECTION.LIFE_STYLE}
      </h1>
      <div className='row m-0'>
        <div className='col-lg-6 mb-6 px-10 d-flex justify-content-center align-items-center'>
          <img
            className='bird-house-img z-index-1'
            src={LIFESTYLE_SECTION.IMAGES.BIRD_HOUSE}
            alt='bird-house'
          />
        </div>
        <div className='col-lg-6 px-7 px-md-10 px-lg-20'>
          <p className='my-2 text-center pl-6 '>{LIFESTYLE_SECTION.INVESTING}</p>
          <p className='my-4 text-center'>{LIFESTYLE_SECTION.INTIMIDATING}</p>
        </div>
      </div>
      <img
        className='cloud-top-small d-none d-md-block position-absolute'
        src={LIFESTYLE_SECTION.IMAGES.CLOUD_TOP_SMALL}
        alt='cloud-top-small'
      />
      <img
        className='cloud-left-top z-index-1 position-absolute d-none d-lg-block'
        src={LIFESTYLE_SECTION.IMAGES.CLOUD_TOP}
        alt='cloud-top'
      />
      <img
        alt='cloud-left-bottom-small'
        className='cloud-left-bottom-small position-absolute z-index-2 d-none d-lg-block'
        src={LIFESTYLE_SECTION.IMAGES.CLOUD_LEFT_SMALL}
      />
      <img
        alt='cloud-left-bottom-big'
        className='cloud-left-bottom-big position-absolute z-index-1 d-none d-lg-block'
        src={LIFESTYLE_SECTION.IMAGES.CLOUD_LEFT_LARGE}
      />
      <img
        alt='cloud-right-bottom-small'
        className='cloud-right-bottom-small position-absolute d-none d-xxl-block'
        src={LIFESTYLE_SECTION.IMAGES.CLOUD_RIGHT_SMALL}
      />
      <img
        alt='cloud-right-bottom-big'
        className='cloud-right-bottom-big d-none d-xxl-block'
        src={LIFESTYLE_SECTION.IMAGES.CLOUD_RIGHT_LARGE}
      />
    </section>
  )
}

export default LifestyleSection
