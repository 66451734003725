import React from 'react'
import {Slider} from 'rsuite'
import 'rsuite/dist/rsuite-no-reset.min.css'
import {SliderProps} from '../../_model'

const Sliders: React.FC<SliderProps> = (props) => {
  const {svgWidth, setSvgWidth, svgNumTriangles, setSvgNumTriangles, xAxisOffset, setAxisOffset} =
    props
  return (
    <div className='row gap-10 mt-10 px-sm-14 px-7 px-md-15 align-content-end flex-wrap mx-3'>
      <div className='row'>
        <label className='fw-bold h5'>Debug sliders:</label>
        <label>Width</label>
        <Slider
          barClassName='slider-track'
          min={1}
          max={200}
          value={svgWidth}
          onChange={(e: any) => setSvgWidth(e)}
        />
        <label className='pt-3'>
          Height of single triangle: (less height more triangles, and vice versa)
        </label>
        <Slider
          barClassName='slider-track'
          min={0}
          max={100}
          value={svgNumTriangles}
          onChange={(e: any) => setSvgNumTriangles(e)}
        />
        <label className='pt-3'>x-axis</label>
        <Slider
          barClassName='slider-track'
          min={-100}
          max={100}
          value={xAxisOffset}
          onChange={(e: any) => setAxisOffset(e)}
        />
      </div>
    </div>
  )
}

export default Sliders
