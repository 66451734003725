import {useRef, useEffect} from 'react'
import * as d3 from 'd3'
import {interpolatePath} from 'd3-interpolate-path'

function StackedBarChart({
  width = '100%',
  height = 400,
  data,
  triangleHeightDecreaseFactor,
  triangleWidthDecreaseFactor,
  overlapMargin,
  minStemHeight,
}) {
  const ref = useRef()
  const previousData = useRef([])

  useEffect(() => {
    draw()
    previousData.current = data
  }, [
    data,
    triangleHeightDecreaseFactor,
    triangleWidthDecreaseFactor,
    overlapMargin,
    minStemHeight,
  ])

  const draw = () => {
    const svg = d3.select(ref.current)
    const currentMaxFutureValue = d3.max(data, (d) => d.futureValue)

    // <----------- Background zooming ----------->
    const originalMountainsHeight = 250
    const originalTriosHeight = 180

    // Mountains
    const mountainsHeightScale = d3
      .scaleLinear()
      .domain([1500, 6584900])
      .range([originalMountainsHeight, originalMountainsHeight * 0.8])
      .clamp(true) // to keep the value within the domain

    const newMountainsHeight = mountainsHeightScale(currentMaxFutureValue)
    // Calculate the change in height and adjust the top value
    const changeInMountainsHeight = originalMountainsHeight - newMountainsHeight
    const newMountainsTop = 50 + changeInMountainsHeight // Divided by 2 to adjust from the center

    // Purple triangles
    const triosHeightScale = d3
      .scaleLinear()
      .domain([1500, 6584900])
      .range([originalTriosHeight, originalTriosHeight * 0.5])
      .clamp(true) // to keep the value within the domain

    const newTriosHeight = triosHeightScale(currentMaxFutureValue)
    // Calculate the change in height and adjust the top value
    const changeInTriosHeight = originalTriosHeight - newTriosHeight
    const newTriosTop = 200 + changeInTriosHeight / 2 // Divided by 2 to adjust from the center
    // <----------- Background zooming ----------->

    // <----------- Draw Tree like shapes ----------->
    const color = {
      initialDeposit: '#8B4513',
      contributions: '#93C47D',
      futureValueWithoutContributions: '#5D9D47',
    }
    let cumulativeX = 0

    const xScale = d3
      .scaleBand()
      .domain(data.map((d, i) => i))
      .range([0, 1100])
      .padding(0.1)

    svg.selectAll('*').remove()

    const stemAnimationDelayTime = 100
    const trianglesAnimationDelayTime = d3.transition().duration(200)
    //  const minStemHeight = 5
    const maxTriangles = 6
    const triangleHeight = 70
    const triangleWidth = xScale.bandwidth()
    const maxStemWidth = xScale.bandwidth() / 6
    const minStemWidth = maxStemWidth / 2
    //  const overlapMargin = 15
    //  const triangleHeightDecreaseFactor = 0.9
    //  const triangleWidthDecreaseFactor = 0.9

    data.forEach((d, index) => {
      // Map the futureValue to tree height
      const treeHeight = d3
        .scaleSqrt()
        .domain([0, d3.max(data, (d) => d.futureValue)])
        .nice()
        .range([0, height])(d.futureValue)

      // Calculate stem height - at least minStemHeight or 20% of treeHeight
      const stemHeight = Math.max(minStemHeight, 0.11 * treeHeight)

      // Calculate the triangle height & count
      const availableHeightForTriangles = treeHeight - stemHeight
      const triangleCount = Math.min(
        maxTriangles,
        Math.floor(availableHeightForTriangles / triangleHeight)
      )

      // Calculate stem width based on futureValue
      const stemWidth = d3
        .scaleSqrt()
        .domain([0, d3.max(data, (d) => d.futureValue)])
        .range([minStemWidth, maxStemWidth])(d.futureValue)

      d.stemHeight = stemHeight
      d.triangleCount = triangleCount
      d.stemWidth = stemWidth
      cumulativeX = index === 0 ? 0 : Math.min(1100, cumulativeX + stemWidth + treeHeight * 0.55)
      d.xPosition = cumulativeX

      // Adjust triangle dimensions based on triangleCount
      switch (d.triangleCount) {
        case 1:
          d.triangleHeightMultiplier = 0.5 // 50% of the original size
          d.triangleWidthMultiplier = 0.5
          break
        case 2:
          d.triangleHeightMultiplier = 0.7 // 70% of the original size for the first triangle
          d.triangleWidthMultiplier = 0.7
          break
        default: // Full size
          d.triangleHeightMultiplier = 1
          d.triangleWidthMultiplier = 1
          break
      }
    })

    svg
      .append('defs')
      .append('filter')
      .attr('id', 'dropShadow')
      .append('feDropShadow')
      .attr('dx', 0)
      .attr('dy', 2)
      .attr('stdDeviation', 2)
      .attr('flood-color', 'rgba(0,0,0,0.3)')

    // Drawing stems
    const stems = svg.selectAll('.stem').data(data)
    stems
      .enter()
      .selectAll('.stem')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'stem')
      .attr('fill', color.initialDeposit)
      .attr('x', (d) => d.xPosition + (xScale.bandwidth() - d.stemWidth) / 2)
      .attr('y', height)
      .attr('width', (d) => d.stemWidth)
      .attr('height', (d) => d.stemHeight)
      .transition()
      .duration(stemAnimationDelayTime)
      .attr('y', (d) => height - d.stemHeight)
      .attr('height', (d) => d.stemHeight)

    // UPDATE
    stems
      .transition()
      .duration(stemAnimationDelayTime)
      .attrTween('y', function (d) {
        const previousY = height - (previousData.current.stemHeight || 0)
        const newY = height - d.stemHeight
        return d3.interpolateNumber(previousY, newY)
      })
      .attrTween('height', function (d) {
        return d3.interpolateNumber(previousData.current.stemHeight || 0, d.stemHeight)
      })

    // Drawing triangles
    const trianglesData = data.flatMap((d) =>
      Array.from({length: d.triangleCount}).map((_, j) => ({
        id: `${d.id}-triangle-${j}`,
        xPosition: d.xPosition,
        stemHeight: d.stemHeight,
        triangleCount: d.triangleCount,
        triangleHeightMultiplier: d.triangleHeightMultiplier,
        triangleWidthMultiplier: d.triangleWidthMultiplier,
        triangleIndex: j,
        treeId: d.id,
      }))
    )

    const triangles = svg.selectAll('.triangle').data(trianglesData, (d) => d.id)

    // ENTER: Create new triangles
    const enterTriangles = triangles
      .enter()
      .append('path')
      .attr('class', 'triangle')
      .attr('fill', color.contributions)
      .attr('d', (d) => getTrianglePath({...d, triangleHeightMultiplier: 0})) // start with 0 height
      .attr('transform', (d) => `translate(0, ${getTriangleYBase(d)})`)

    enterTriangles
      .transition(trianglesAnimationDelayTime)
      .attr('d', (d) => getTrianglePath(d))
      .attr('transform', (d) => `translate(0, ${getTriangleYBase(d)})`)

    // UPDATE: Update existing triangles without transition
    triangles
      .attr('d', (d) => getTrianglePath(d))
      .attr('transform', (d) => `translate(0, ${getTriangleYBase(d)})`)

    // EXIT: Remove unnecessary triangles with animation
    triangles
      .exit()
      .transition(trianglesAnimationDelayTime)
      .attr('d', (d) => getTrianglePath({...d, triangleHeightMultiplier: 0})) // shrink to 0 height
      .attr('transform', (d) => `translate(0, ${height})`)
      .remove()

    function getTrianglePath(d) {
      const xBase = d.xPosition
      const currentTriangleHeight =
        triangleHeight *
        Math.pow(triangleHeightDecreaseFactor, d.triangleIndex) *
        d.triangleHeightMultiplier
      const currentTriangleWidth =
        triangleWidth *
        Math.pow(triangleWidthDecreaseFactor, d.triangleIndex) *
        d.triangleWidthMultiplier
      const x1 = xBase + (triangleWidth - currentTriangleWidth) / 2
      const y1 = 0
      const x2 = x1 + currentTriangleWidth
      const y2 = 0
      const x3 = xBase + triangleWidth / 2
      const y3 = -currentTriangleHeight // Negative because the triangle points upwards

      return `M${x1} ${y1} L${x2} ${y2} L${x3} ${y3} Z`
    }

    function getTriangleYBase(d) {
      //   const trianglesForTree = trianglesData.filter((t) => t.treeId === d.treeId)
      let yOffset = height - d.stemHeight

      // Sum up the height of all triangles that are below the current one
      for (let i = 0; i < d.triangleIndex; i++) {
        yOffset -=
          triangleHeight * Math.pow(triangleHeightDecreaseFactor, i) * d.triangleHeightMultiplier -
          overlapMargin
      }

      return yOffset
    }
    // <----------- Draw Tree like shapes ----------->

    // <----------- Apply the zoom ----------->
    const bgMountains = document.querySelector('.bg-mountains')
    bgMountains.style.height = `${newMountainsHeight}px`
    bgMountains.style.top = `${newMountainsTop}px`

    const bgTrios = document.querySelector('.bg-trios')
    bgTrios.style.height = `${newTriosHeight}px`
    bgTrios.style.top = `${newTriosTop}px`
    // <----------- Apply the zoom ----------->

    // <----------- Tree shadows ----------->
    const shadowHeight = 10 // You can adjust this based on your design requirements

    const shadows = svg.selectAll('.shadow').data(data)

    shadows
      .enter()
      .insert('rect', '.stem') // Ensure the shadow is drawn behind the stem
      .attr('class', 'shadow')
      .attr('x', (d) => d.xPosition + (xScale.bandwidth() - triangleWidth * 0.5) / 2)
      .attr('y', height - minStemHeight / 2 - shadowHeight / 1.4)
      .attr('width', (d) => triangleWidth * 0.5)
      .attr('height', shadowHeight)
      .attr('rx', 5) // Rounded corners
      .attr('ry', 5)
      .attr('fill', 'black')
      .style('opacity', 0.15)
      .attr('filter', 'url(#dropShadow)')

    // UPDATE
    shadows
      .attr('x', (d) => d.xPosition + (xScale.bandwidth() - triangleWidth * 0.5) / 2)
      .attr('width', (d) => triangleWidth * 0.5)
    // <----------- Tree shadows ----------->
  }

  return (
    <div className='position-relative chart' style={{paddingBottom: '75px'}}>
      <div className='bg-mountains'></div>
      <div className='bg-trios'></div>
      <svg ref={ref} width={width} height={height}></svg>
    </div>
  )
}

export default StackedBarChart
