import {FC, useState, useEffect} from 'react'
import {fetchWaitlistCount} from './core/_request'
import Loader from '../../../components/loader/Loader'
import MessageComponent from '../../../components/message/MessageComponent'

const DashboardPage: FC = () => {
  const [loading, setloading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [waitlistCount, setwaitlistCount] = useState<number>()

  const handleFetchWaitlistCount = async () => {
    setloading(true)
    try {
      const res = await fetchWaitlistCount()
      setwaitlistCount(res.data.count)
      setloading(false)
    } catch (error: Error | unknown) {
      setIsError(true)
      setloading(false)
    }
  }

  useEffect(() => {
    handleFetchWaitlistCount()
  }, [])

  const handleShowCounts = (loading: boolean) => {
    switch (loading) {
      case true:
        return <Loader />
      case false:
        return (
          <>
            {isError ? (
              <MessageComponent message='An error occured. Please try again later' />
            ) : (
              <>
                {/* blogs count */}
                <div className='card col-md-4 me-md-4 mb-4 mb-md-0'>
                  <div className='card-body'>
                    {waitlistCount === 0 ? (
                      <p className='card-text'>No user found in waitlist</p>
                    ) : (
                      <>
                        <h1 className='text-primary'>{waitlistCount}</h1>
                        <p className='card-text'>Users in waitlist</p>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )
      default:
        return null
    }
  }

  return <>{handleShowCounts(loading)}</>
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
