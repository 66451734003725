import {useEffect} from 'react'
import {useThemeMode} from '../../../_metronic/partials'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']

const ComingSoon = () => {
  const {mode} = useThemeMode()
  useEffect(() => {
    BODY_CLASSES.forEach((c) => document.body.classList.add(c))
    document.body.style.backgroundImage =
      mode === 'dark'
        ? `url(${toAbsoluteUrl('/media/home/mountains.svg')})`
        : `url(${toAbsoluteUrl('/media/home/mountains.svg')})`

    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
      document.body.style.backgroundImage = 'none'
    }
  }, [mode])

  return (
    <div className='d-flex flex-column flex-root' style={{height: '100vh'}}>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5' style={{border: '5px solid #c9ecee'}}>
            <div className='card-body py-15 py-lg-20'>
              <img src={toAbsoluteUrl('/monolith-logo.png')} alt='mountains' />
              <h1 className='mt-7'>Coming Soon</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ComingSoon}
