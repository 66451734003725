import {useRef, useEffect, useState} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Spinner} from 'react-bootstrap'
import MetaTags from '../../../Meta/MetaTags'
import LifestyleSection from '../../modules/homeComponents/LifestyleSection'
import HeroSection from '../../modules/homeComponents/HeroSection'
import SameMathNewLookSection from '../../modules/homeComponents/SameMathNewLookSection'
import BeginnersSection from '../../modules/homeComponents/BeginnersSection'
import StayTunedSection from '../../modules/homeComponents/StayTunedSection'
import FinanciallyFreeSection from '../../modules/homeComponents/FinanciallyFreeSection'
import ProjectionsSection from '../../modules/homeComponents/projections/ProjectionsSection'
import {InvestmentTableModel} from '../../modules/homeComponents/projections/components/_model'
import ProjectionsTable from '../../modules/homeComponents/projections/components/ProjectionsTable'
import {fetchAdvisorFormData} from '../../modules/advisor/core/_request'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import {
  assets,
  defaultAsset,
} from '../../modules/homeComponents/projections/components/ProjectionsConfig'
import {PROJECTIONS_TABLE, TYPES_OF_ROR} from '../../../constants/AppConstants'
import TimeSeriesSection from '../../modules/homeComponents/TSBG/TimeSeriesSection'
import useScreenWidth from '../../modules/hooks/useScreenWidth'
import Pictograph from '../../modules/homeComponents/pictograph/Pictograph'

const ARQPage = () => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)
  const [manualScrollOccurred, setManualScrollOccurred] = useState(false)

  // extracting useparams
  const {advisor_url} = useParams()
  const ProjectionsWebSectionRef = useRef<HTMLDivElement>(null)
  // Use the useScreenWidth hook to get the screen width
  const screenWidth = useScreenWidth()
  const location = useLocation()
  // Check if "arq" is in the URL
  const pathsToCheck = ['arq/home', 'advisor-demo1/home', 'advisor-demo2/home', 'waystone/home']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))

  // destructuring useReceiverFormContext hook
  const {isReceiverDataLoading, setIsReceiverDataLoading, setReceiverFormDataObj} =
    useReceiverFormContext()

  // if url contains advisor_url as a param in it than call fetch API
  const handleFetchAdvisorData = async () => {
    try {
      if (advisor_url) {
        const res = await fetchAdvisorFormData(advisor_url)
        setReceiverFormDataObj({
          starting_amount: res.data.advisorFormData.starting_amount,
          contributions: res.data.advisorFormData.contributions,
          contributions_frequency: res.data.advisorFormData.contributions_frequency,
          custom_investment_values: res.data.customInvestments,
          years_to_grow: res.data.advisorFormData.years_to_grow,
          greetings: res.data.advisorFormData.greeting_message,
          logo_url: res.data.advisorFormData.logo,
          ARQ_Flip_Gain: res.data.advisorFormData.ARQ_Flip_Gain,
          ARQ_rental_income: res.data.advisorFormData.ARQ_rental_income,
          ARQ_rental_contribution: res.data.advisorFormData.ARQ_rental_contribution,
          ARQ_appreciation: res.data.advisorFormData.ARQ_appreciation,
          ARQ_property_worth: res.data.advisorFormData.ARQ_property_worth,
          // as we are showing the distributor's percent so that's why deducting 100 from management_fee
          management_fee: 100 - res.data.advisorFormData.management_fee,
          selected_rate_of_return_obj:
            assets.find((asset) => asset.id === res.data.advisorFormData.rate_of_return) ||
            assets[defaultAsset],
          custom_email: res.data.advisorFormData.custom_email,
          selected_bars: res.data.advisorFormData.selected_bars,
        })

        const customAssetIndex = assets.findIndex((asset) => asset.label === 'Custom')
        if (customAssetIndex !== -1 && res.data?.customInvestments.length > 0) {
          // Remove the last index
          assets.pop()

          var arq_fix_n_flip_legacy = {
            id: assets.length + 1, // Incrementing the ID
            label: 'ARQ Fix & Flip (Legacy)',
            dropdownLabel: `ARQ Fix & Flip (Legacy)`,
            customLabel: `2%`,
            color: '#9A258E', // Different color for the second asset
            value: 2, // Different value for the second asset
            type: TYPES_OF_ROR.TYPE_ARQ_FLIP,
            isLegacy: true,
          }

          // Creating a new object for arq_rental_legacy
          var arq_rental_legacy = {
            id: assets.length + 2, // Incrementing the ID
            label: 'ARQ Rental (Legacy)', // Different label for the third asset
            dropdownLabel: `ARQ Rental (Legacy)`, // Different dropdown label for the third asset
            customLabel: `3%`, // Different custom label for the third asset
            color: '#9A258E', // Different color for the third asset
            value: 3, // Different value for the third asset
            type: TYPES_OF_ROR.TYPE_ARQ_RENTAL,
            isLegacy: true,
          }
          assets.push(arq_fix_n_flip_legacy, arq_rental_legacy)
          for (const customInvestment of res.data?.customInvestments) {
            const newAsset = {
              id: assets.length + 1,
              label: customInvestment.custom_investment_label,
              dropdownLabel: `(${customInvestment.custom_investment_value}%) ${customInvestment.custom_investment_label}`,
              customLabel: `${customInvestment.custom_investment_value}%`,
              color: '#9A258E',
              value: parseFloat(customInvestment.custom_investment_value),
              type: customInvestment.type_of_ror,
            }

            assets.push(newAsset)
          }
          // Add custom investments from the customInvestments array
        }
        setReceiverFormDataObj((prevFormData) => ({
          ...prevFormData,
          selected_rate_of_return_obj:
            assets.find((asset) => asset.id === res.data.advisorFormData.rate_of_return) ||
            assets[defaultAsset],
        }))
        // Process the response here
      } else {
        // Handle the case when advisor_url is undefined or null
      }
    } catch (error: any) {
      if (error?.response?.data) {
        notifyError(error?.response?.data?.message)
      } else {
        notifyError('Error occurred during the request.')
      }
    } finally {
      setIsReceiverDataLoading(false)
    }
  }

  useEffect(() => {
    if (advisor_url) {
      handleFetchAdvisorData()
    } else {
      setIsReceiverDataLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isArqInURL) {
      // extracting last index of assets array(assuming that last index is always a custom bar)
      const customAssetIndex = assets.length - 1
      // Update the value for the (Rate of Return) object with the label "Custom"
      assets[customAssetIndex].label = 'Google'
      assets[customAssetIndex].dropdownLabel = `(25%) Google`
      assets[customAssetIndex].customLabel = `25%`
      assets[customAssetIndex].value = 25
    }
  }, [])

  // stay tuned ref
  const stayTunedSectionRef = useRef<HTMLDivElement>(null)
  // projections ref
  const ProjectionsSectionRef = useRef<HTMLDivElement>(null)

  // handle click stay tuned section
  const handleClickStayTuned = () => {
    if (stayTunedSectionRef.current) {
      const scrollOptions: ScrollToOptions = {
        top: stayTunedSectionRef.current.offsetTop,
        behavior: 'smooth',
      }
      window.scrollTo(scrollOptions)
    }
  }
  // handle click projections section
  const handleClickProjections = () => {
    if (ProjectionsSectionRef.current) {
      const scrollOptions: ScrollToOptions = {
        top: ProjectionsSectionRef?.current?.offsetTop,
        behavior: 'smooth',
      }
      window.scrollTo(scrollOptions)
    }
  }
  // handle click projections section
  const handleWebClickProjections = () => {
    if (ProjectionsWebSectionRef.current) {
      const scrollOptions: ScrollToOptions = {
        top:
          ProjectionsWebSectionRef?.current?.offsetTop -
          ProjectionsWebSectionRef?.current?.offsetTop * 0.16,
        behavior: 'smooth',
      }
      window.scrollTo(scrollOptions)
    }
  }
  // Add an event listener to detect manual scroll
  useEffect(() => {
    const handleScroll = () => {
      // Manual scroll has occurred, set the state variable to true
      setManualScrollOccurred(true)
    }

    // Add the event listener to the window
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  // Conditionally call the appropriate scroll function based on screen width
  useEffect(() => {
    // Check if manual scroll has occurred
    if (!manualScrollOccurred) {
      // Set up the setTimeout with a 10-second delay
      const scrollTimerId = setTimeout(() => {
        if (screenWidth >= 768) {
          handleWebClickProjections()
        } else {
          handleClickProjections()
        }
      }, 10000)

      // Clean up the setTimeout on component unmount to avoid memory leaks
      return () => {
        clearTimeout(scrollTimerId)
      }
    }
  }, [screenWidth, manualScrollOccurred])
  const [tableData, setTableData] = useState<InvestmentTableModel[]>([])
  const handleDataChange = (newData: InvestmentTableModel[]) => {
    // Process the newData or update the state accordingly
    setTableData(newData)
  }
  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags URL={window.location.href} />

      <section className='container'>
        <HeroSection
          handleClickProjections={
            screenWidth >= 768 ? handleWebClickProjections : handleClickProjections
          }
          handleClickStayTuned={handleClickStayTuned}
          //  refProp={ProjectionsWebSectionRef}
        />
        <section className='d-block d-md-none px-6 px-sm-10 mb-20'>
          {!isReceiverDataLoading ? (
            <>
              <ProjectionsSection
                refProp={ProjectionsSectionRef}
                //  onTableDataChange={handleDataChange}
              />
              <TimeSeriesSection />

              <h1 className='my-2 mb-6 text-center z-index-1 '>{PROJECTIONS_TABLE.TITLE}</h1>
              <ProjectionsTable tableData={tableData} />
            </>
          ) : (
            <div className='text-center mt-10'>
              <Spinner animation='grow' style={{background: '#9b258f'}} />
            </div>
          )}
        </section>
        <Pictograph />
        <LifestyleSection />
        <SameMathNewLookSection />
        <BeginnersSection />
        <StayTunedSection refProp={stayTunedSectionRef} />
        <FinanciallyFreeSection />
      </section>
    </>
  )
}

export {ARQPage}
