import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {WorthData} from '../../../advisor/components/_model'
import {CompoundingGrowth, InvestmentChartProps, InvestmentTableModel} from './_model'

export const calculateFutureValue = (
  initialDeposit: number,
  compoundingGrowth: number,
  contributionFrequency: number,
  numberOfYears: number,
  contributions: number
): number => {
  const n = contributionFrequency
  const t = numberOfYears
  const i = compoundingGrowth / 100 / n // Convert rate of returns to decimal
  const compoundFactor = Math.pow(1 + i, n * t)
  const principalComponent = initialDeposit * compoundFactor
  const contributionComponent = contributions * ((compoundFactor - 1) / i)
  const futureValue = principalComponent + contributionComponent
  return futureValue // Rounded to 2 decimal places
}

const calculatePropertyWorthPercentages = (
  value: number,
  ARQ_property_worth: number,
  multiplier: number
): number => {
  const ARQ_initial_deposit = ARQ_property_worth !== 0 ? value / ARQ_property_worth : 0
  return multiplier * ARQ_initial_deposit
}

const calculateAfterManagementFeePercentages = (
  value: number,
  management_fee_percentage: number
): number => {
  const management_fee = management_fee_percentage / 100
  return value - value * management_fee
}

export const generateChartData = (
  initialDeposit: number,
  contributions: number,
  contributionFrequency: number,
  numberOfYears: number,
  compoundingGrowth: CompoundingGrowth[],
  ARQ_Flip_Gain: number,
  ARQ_rental_income: number,
  ARQ_rental_contribution: number,
  ARQ_appreciation: number,
  ARQ_property_worth: number,
  management_fee: number
): InvestmentChartProps[] => {
  const chartData = compoundingGrowth.map((rate) => {
    const flipGainWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      ARQ_Flip_Gain,
      ARQ_property_worth,
      initialDeposit
    )
    const rentalIncomeWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      ARQ_rental_income,
      ARQ_property_worth,
      initialDeposit
    )
    const appreciationWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      ARQ_appreciation,
      ARQ_property_worth,
      initialDeposit
    )

    // Calculate values after subtracting management fees
    const flipGainAfterManagementFee = calculateAfterManagementFeePercentages(
      flipGainWithPropertyWorthPercentage,
      100 - management_fee
    )

    const rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
      rentalIncomeWithPropertyWorthPercentage,
      100 - management_fee
    )

    const appreciationAfterManagementFee = calculateAfterManagementFeePercentages(
      appreciationWithPropertyWorthPercentage,
      100 - management_fee
    )

    let futureValue = 0
    let gains = 0
    let customROR: number | null = null
    let rentalIncome = 0
    let futureValueWithoutContributions = 0

    let isLegacy =
      rate.type !== TYPES_OF_ROR.TYPE_SIMPLE &&
      (rate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL || rate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP)
        ? true
        : false
    if (rate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP) {
      futureValue = initialDeposit + flipGainWithPropertyWorthPercentage
      customROR = initialDeposit > 0 ? Math.abs(initialDeposit - futureValue) / initialDeposit : 0
      futureValueWithoutContributions = futureValue
    } else if (rate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL) {
      rentalIncome =
        rentalIncomeWithPropertyWorthPercentage * ARQ_rental_contribution * numberOfYears
      futureValue =
        initialDeposit +
        (flipGainWithPropertyWorthPercentage +
          rentalIncome +
          appreciationWithPropertyWorthPercentage)
      gains =
        flipGainWithPropertyWorthPercentage + rentalIncome + appreciationWithPropertyWorthPercentage
      customROR =
        initialDeposit > 0 ? gains / initialDeposit / numberOfYears : gains / numberOfYears
      futureValueWithoutContributions = futureValue
    } else if (rate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT) {
      rentalIncome = rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
      futureValue =
        initialDeposit +
        (flipGainAfterManagementFee + rentalIncome + appreciationAfterManagementFee)
      gains = flipGainAfterManagementFee + rentalIncome + appreciationAfterManagementFee
      customROR =
        initialDeposit > 0 ? gains / initialDeposit / numberOfYears : gains / numberOfYears
      futureValueWithoutContributions = futureValue
    } else if (rate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT) {
      rentalIncome = rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
      futureValue = initialDeposit + rentalIncome

      gains = rentalIncome
      customROR =
        initialDeposit > 0 ? gains / initialDeposit / numberOfYears : gains / numberOfYears
      futureValueWithoutContributions = futureValue
    } else if (rate.type === TYPES_OF_ROR.TYPE_SIMPLE && rate.value !== 0) {
      futureValue = calculateFutureValue(
        initialDeposit,
        rate.value,
        contributionFrequency,
        numberOfYears,
        contributions
      )

      futureValueWithoutContributions =
        futureValue - contributions * contributionFrequency * numberOfYears - initialDeposit
    }

    return {
      id: rate.id,
      label: rate.label,
      customLabel: rate.customLabel,
      labelColor: rate.color,
      futureValue: parseFloat(futureValue.toFixed(2)),
      contributions: contributions * contributionFrequency * numberOfYears,
      initialDeposit,
      isLoss: rate.value < 0 && customROR === null,
      numberOfYears: numberOfYears,
      futureValueWithoutContributions,
      ARQ_Flip_Gain: isLegacy ? flipGainWithPropertyWorthPercentage : flipGainAfterManagementFee,
      type: rate.type,
      ARQ_rental_income: rentalIncome,
      ARQ_rental_contribution,
      ARQ_appreciation: isLegacy
        ? appreciationWithPropertyWorthPercentage
        : appreciationAfterManagementFee,
      ARQ_property_worth,
      customROR: customROR && customROR * 100,
      originalARQValues: {
        proportionValue: ARQ_property_worth !== 0 ? (initialDeposit / ARQ_property_worth) * 100 : 0,
        fixAndFlip: ARQ_Flip_Gain,
        rental: ARQ_rental_income * ARQ_rental_contribution * numberOfYears,
        appreciation: ARQ_appreciation,
        management_fee: management_fee,
      },
    }
  })

  return chartData
}

// This function calculates the future value of an investment based on various parameters.
export const calculateAdvisorFutureValue = (
  initialDeposit: number,
  selectedRate: CompoundingGrowth,
  contributions: number,
  contributionFrequency: number,
  numberOfYears: number,
  ARQ_Flip_Gain: number,
  ARQ_rental_income: number,
  ARQ_rental_contribution: number,
  ARQ_appreciation: number,
  ARQ_property_worth: number,
  management_fee: number
): WorthData => {
  // Calculate percentages based on property worth for flip gain, rental income, and appreciation
  const flipGainWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
    ARQ_Flip_Gain,
    ARQ_property_worth,
    initialDeposit
  )
  const rentalIncomeWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
    ARQ_rental_income,
    ARQ_property_worth,
    initialDeposit
  )
  const appreciationWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
    ARQ_appreciation,
    ARQ_property_worth,
    initialDeposit
  )

  // Calculate values after subtracting management fees
  const flipGainAfterManagementFee = calculateAfterManagementFeePercentages(
    flipGainWithPropertyWorthPercentage,
    management_fee
  )

  const rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
    rentalIncomeWithPropertyWorthPercentage,
    management_fee
  )

  const appreciationAfterManagementFee = calculateAfterManagementFeePercentages(
    appreciationWithPropertyWorthPercentage,
    management_fee
  )

  let futureValue = 0
  let rentalIncome = 0
  // Determine the type of compounding growth rate selected
  if (selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP) {
    // Calculate future value for ARQ_FLIP scenario
    futureValue = initialDeposit + flipGainWithPropertyWorthPercentage
    futureValue += flipGainWithPropertyWorthPercentage + appreciationWithPropertyWorthPercentage
  } else if (selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL) {
    // Calculate future value for ARQ_RENTAL scenario
    rentalIncome = rentalIncomeWithPropertyWorthPercentage * ARQ_rental_contribution * numberOfYears
    futureValue =
      initialDeposit +
      (flipGainWithPropertyWorthPercentage + rentalIncome + appreciationWithPropertyWorthPercentage)
  } else if (selectedRate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT) {
    // Calculate future value for AFTER_EXIT scenario
    rentalIncome = rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
    futureValue =
      initialDeposit + (flipGainAfterManagementFee + rentalIncome + appreciationAfterManagementFee)
  } else if (selectedRate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT) {
    // Calculate future value for BEFORE_EXIT scenario
    rentalIncome = rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
    futureValue = initialDeposit + rentalIncome
  } else {
    // Calculate future value using a different compounding rate
    futureValue = calculateFutureValue(
      initialDeposit,
      selectedRate.value,
      contributionFrequency,
      numberOfYears,
      contributions
    )
  }

  return {
    futureValue,
    initialDeposit,
    appreciation:
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP ||
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL
        ? appreciationWithPropertyWorthPercentage
        : appreciationAfterManagementFee,
    fixAndFlip:
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP ||
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL
        ? flipGainWithPropertyWorthPercentage
        : flipGainAfterManagementFee,
    rentalIncome,
    originalARQValues: {
      proportionValue: ARQ_property_worth !== 0 ? (initialDeposit / ARQ_property_worth) * 100 : 0,
      fixAndFlip: ARQ_Flip_Gain,
      rental: ARQ_rental_income * ARQ_rental_contribution * numberOfYears,
      appreciation: ARQ_appreciation,
      management_fee: management_fee,
    },
  }
}

export const generateTableData = (
  initialDeposit: number,
  contributions: number,
  contributionFrequency: number,
  numberOfYears: number,
  selectedRate: CompoundingGrowth,
  flipGain: number,
  propertyWorth: number,
  appreciation: number,
  rentalFrequency: number,
  rentalIncome: number,
  management_fee: number
): InvestmentTableModel[] => {
  const data: InvestmentTableModel[] = []
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  let cumulativeContributions = 0
  let cumulativeGains = 0
  let previousFutureValue = 0

  const calculateDataForPeriod = (period: number, frequency: number): InvestmentTableModel => {
    const futureValue = calculateFutureValue(
      initialDeposit,
      selectedRate.value,
      contributionFrequency,
      period,
      contributions
    )

    const contributionsThisPeriod = contributions * frequency
    cumulativeContributions += contributionsThisPeriod

    const gainThisPeriod =
      period === 1
        ? futureValue - initialDeposit - contributionsThisPeriod
        : futureValue - previousFutureValue - contributionsThisPeriod
    cumulativeGains += gainThisPeriod

    const monthIndex = (currentMonth + Math.floor(period * 12)) % 12
    const year = currentYear + Math.floor(period)
    const currentDateForMonth = new Date(year, monthIndex, 1)
    const monthName = currentDateForMonth.toLocaleString('default', {month: 'long'})
    const totalCashOnCash = gainThisPeriod / initialDeposit
    previousFutureValue = futureValue
    const roi =
      (futureValue - (initialDeposit + cumulativeContributions)) /
      (initialDeposit + cumulativeContributions)

    return {
      yearOrMonth: numberOfYears === 1 ? monthName : year.toString(),
      initialDeposit: +initialDeposit.toFixed(2),
      contributionsThisPeriod: +contributionsThisPeriod.toFixed(2),
      cumulativeContributions: +cumulativeContributions.toFixed(2),
      gainThisPeriod: selectedRate.value !== 0 ? +gainThisPeriod.toFixed(2) : 0,
      cumulativeGains: selectedRate.value !== 0 ? +cumulativeGains.toFixed(2) : 0,
      futureValue: selectedRate.value !== 0 ? +futureValue.toFixed(2) : 0,
      isMonthly: numberOfYears === 1,
      selectedRate: selectedRate.dropdownLabel || '',
      customROR: null,
      ROR: (+roi * 100).toFixed(2) + '%',
      flipGain: null,
      rentalIncome: null,
      appreciation: null,
      cashOnCash: null,
      totalCashOnCash: +(totalCashOnCash * 100).toFixed(2),
      isLoss: selectedRate.value < 0,
      originalARQValues: {
        proportionValue: 0,
        fixAndFlip: 0,
        rental: 0,
        appreciation: 0,
        management_fee: 0,
      },
    }
  }

  const calculateRentalProfits = (period: number, isLegacy: boolean): InvestmentTableModel => {
    const flipGainWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      flipGain,
      propertyWorth,
      initialDeposit
    )

    const rentalIncomeWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      rentalIncome,
      propertyWorth,
      initialDeposit
    )

    const appreciationWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      appreciation,
      propertyWorth,
      initialDeposit
    )

    // Calculate values after subtracting management fees
    const flipGainAfterManagementFee = calculateAfterManagementFeePercentages(
      flipGainWithPropertyWorthPercentage,
      100 - management_fee
    )

    const rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
      rentalIncomeWithPropertyWorthPercentage,
      100 - management_fee
    )

    const appreciationAfterManagementFee = calculateAfterManagementFeePercentages(
      appreciationWithPropertyWorthPercentage,
      100 - management_fee
    )

    const final_fixAndFlip = isLegacy
      ? flipGainWithPropertyWorthPercentage
      : flipGainAfterManagementFee
    const final_rentalIncome = isLegacy
      ? rentalIncomeWithPropertyWorthPercentage
      : rentalIncomeAfterManagementFee
    const final_appreciation = isLegacy
      ? appreciationWithPropertyWorthPercentage
      : appreciationAfterManagementFee
    let rentalIncomeThisPeriod = final_rentalIncome * rentalFrequency
    const incrementalIncome = rentalIncomeThisPeriod * period
    const final_fixAndFlip_yearly = isLegacy
      ? final_fixAndFlip
      : period === numberOfYears || (numberOfYears === 1 && period === 12)
      ? final_fixAndFlip
      : 0
    const futureValue =
      initialDeposit + final_fixAndFlip_yearly + incrementalIncome + final_appreciation * period

    const gains = Math.abs(futureValue - initialDeposit)

    const customROR = initialDeposit > 0 ? gains / initialDeposit / period : gains / period
    const ror = initialDeposit > 0 ? gains / initialDeposit : gains
    const monthIndex = (currentMonth + Math.floor(period * 12)) % 12
    const year = currentYear + Math.floor(period)
    const currentDateForMonth = new Date(year, monthIndex, 1)
    const monthName = currentDateForMonth.toLocaleString('default', {month: 'long'})
    const totalCashOnCash = incrementalIncome / initialDeposit
    const cashOnCash = (rentalIncomeThisPeriod + final_fixAndFlip_yearly) / initialDeposit
    if (numberOfYears === 1) {
      rentalIncomeThisPeriod = rentalIncomeThisPeriod / 12
    }
    const tableData: InvestmentTableModel = {
      yearOrMonth: numberOfYears === 1 ? monthName : year.toString(),
      initialDeposit: +initialDeposit.toFixed(2),
      contributionsThisPeriod: null,
      cumulativeContributions: null,
      gainThisPeriod: null,
      cumulativeGains: null,
      futureValue: +futureValue.toFixed(2),
      isMonthly: numberOfYears === 1,
      selectedRate: selectedRate.dropdownLabel || '',
      customROR: `${(customROR * 100).toFixed(2)}%`,
      ROR: `${(ror * 100).toFixed(2)}%`,
      flipGain: final_fixAndFlip_yearly,
      rentalIncome: rentalIncomeThisPeriod,
      appreciation: final_appreciation,
      cashOnCash: +(cashOnCash * 100).toFixed(2),
      totalCashOnCash: +(totalCashOnCash * 100).toFixed(2),
      isLoss: false,
      originalARQValues: {
        proportionValue: propertyWorth !== 0 ? (initialDeposit / propertyWorth) * 100 : 0,
        fixAndFlip: flipGain,
        rental: rentalIncome * rentalFrequency * period,
        appreciation: appreciation,
        management_fee: 0,
      },
    }

    return tableData
  }

  const calculateBeforeExitProfits = (period: number, frequency: number): InvestmentTableModel => {
    const rentalIncomeWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      rentalIncome,
      propertyWorth,
      initialDeposit
    )

    const rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
      rentalIncomeWithPropertyWorthPercentage,
      100 - management_fee
    )

    let rentalIncomeThisPeriod = rentalIncomeAfterManagementFee * rentalFrequency
    const incrementalIncome = rentalIncomeThisPeriod * period
    const futureValue = initialDeposit + incrementalIncome

    const gains = incrementalIncome * period

    var customROR = initialDeposit > 0 ? gains / initialDeposit / period : gains / period
    const ror = customROR
    customROR = customROR / period

    const monthIndex = (currentMonth + Math.floor(period * 12)) % 12
    const year = currentYear + Math.floor(period)
    const currentDateForMonth = new Date(year, monthIndex, 1)
    const monthName = currentDateForMonth.toLocaleString('default', {month: 'long'})
    const totalCashOnCash = incrementalIncome / initialDeposit
    const cashOnCash = rentalIncomeThisPeriod / initialDeposit

    if (numberOfYears === 1) {
      rentalIncomeThisPeriod = rentalIncomeThisPeriod / 12
    }
    const tableData: InvestmentTableModel = {
      yearOrMonth: numberOfYears === 1 ? monthName : year.toString(),
      initialDeposit: +initialDeposit.toFixed(2),
      contributionsThisPeriod: null,
      cumulativeContributions: null,
      gainThisPeriod: null,
      cumulativeGains: null,
      futureValue: +futureValue.toFixed(2),
      isMonthly: numberOfYears === 1,
      selectedRate: selectedRate.dropdownLabel || '',
      customROR: `${(customROR * 100).toFixed(2)}%`,
      ROR: `${(ror * 100).toFixed(2)}%`,
      flipGain: 0,
      rentalIncome: rentalIncomeThisPeriod,
      appreciation: 0,
      isLoss: false,
      cashOnCash: +(cashOnCash * 100).toFixed(2),
      totalCashOnCash: +(totalCashOnCash * 100).toFixed(2),
      originalARQValues: {
        proportionValue: propertyWorth !== 0 ? (initialDeposit / propertyWorth) * 100 : 0,
        fixAndFlip: flipGain,
        rental: rentalIncome * rentalFrequency * period,
        appreciation: 0,
        management_fee: management_fee,
      },
    }

    return tableData
  }
  if (selectedRate.type === TYPES_OF_ROR.TYPE_SIMPLE) {
    if (numberOfYears === 1) {
      // Loop through each month within the year
      for (let month = 1; month <= 12; month++) {
        data.push(calculateDataForPeriod(month / 12, 1))
      }
    } else {
      for (let i = 1; i <= numberOfYears; i++) {
        data.push(calculateDataForPeriod(i, contributionFrequency))
      }
    }
  } else {
    let futureValue = 0
    let gains = 0
    let customROR = null
    const flipGainWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      flipGain,
      propertyWorth,
      initialDeposit
    )
    const appreciationWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      appreciation,
      propertyWorth,
      initialDeposit
    )

    if (selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP) {
      futureValue = initialDeposit + flipGainWithPropertyWorthPercentage
      gains = flipGainWithPropertyWorthPercentage + appreciationWithPropertyWorthPercentage
      customROR = initialDeposit > 0 ? Math.abs(initialDeposit - futureValue) / initialDeposit : 0

      const tableData: InvestmentTableModel = {
        yearOrMonth: currentYear.toString(),
        initialDeposit: +initialDeposit.toFixed(2),
        contributionsThisPeriod: null,
        cumulativeContributions: null,
        gainThisPeriod: null,
        cumulativeGains: null,
        futureValue: +futureValue.toFixed(2),
        isMonthly: null,
        selectedRate: selectedRate.dropdownLabel || '',
        customROR: `${(customROR * 100).toFixed(2)}%`,
        ROR: `${(customROR * 100).toFixed(2)}%`,
        flipGain: flipGainWithPropertyWorthPercentage,
        rentalIncome: rentalIncome,
        appreciation: 0,
        cashOnCash: null,
        totalCashOnCash: null,
        isLoss: false,
        originalARQValues: {
          proportionValue: propertyWorth !== 0 ? (initialDeposit / propertyWorth) * 100 : 0,
          fixAndFlip: flipGain,
          rental: 0,
          appreciation: 0,
          management_fee: management_fee,
        },
      }

      data.push(tableData)
    } else if (
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL ||
      selectedRate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT
    ) {
      if (numberOfYears === 1) {
        // Loop through each month within the year for rental calculations
        for (let month = 1; month <= 12; month++) {
          data.push(
            calculateRentalProfits(month / 12, selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL)
          )
        }
      } else {
        for (let i = 1; i <= numberOfYears; i++) {
          data.push(calculateRentalProfits(i, selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL))
        }
      }
    } else if (selectedRate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT) {
      if (numberOfYears === 1) {
        // Loop through each month within the year for rental calculations
        for (let month = 1; month <= 12; month++) {
          data.push(calculateBeforeExitProfits(month / 12, 1))
        }
      } else {
        for (let i = 1; i <= numberOfYears; i++) {
          data.push(calculateBeforeExitProfits(i, rentalFrequency))
        }
      }
    }
  }

  return data
}
