import React, {createContext, useState, useContext, Dispatch, SetStateAction, useMemo} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {CustomInvestments} from '../modules/advisor/components/_model'
import {
  assets,
  defaultAsset,
  typesOfRoR,
} from '../modules/homeComponents/projections/components/ProjectionsConfig'
import {CompoundingGrowth} from '../modules/homeComponents/projections/components/_model'

type ReceiverFormData = {
  starting_amount: number
  contributions: number
  contributions_frequency: number
  // rate_of_return: number
  custom_investment_values: CustomInvestments[]
  years_to_grow: number
  greetings: string
  logo_url: string
  ARQ_Flip_Gain: number
  ARQ_rental_income: number
  ARQ_rental_contribution: number
  ARQ_appreciation: number
  ARQ_property_worth: number
  management_fee: number
  selected_rate_of_return_obj: CompoundingGrowth
  custom_email: string
  selected_bars: Array<number>
}

type OriginalARQValues = {
  proportionValue: number | null
  fixAndFlip: number | null
  rental: number | null
  appreciation: number | null
  management_fee: number
}

type TimeSeriesBarGraphData = {
  yearOrMonth: string
  isMonthly: boolean | null
  futureValue: number
  initialDeposit: number | null
  contributionsThisPeriod: number | null
  flipGain: number | null
  rentalIncome: number | null
  appreciation: number | null
  isLoss: boolean
  originalARQValues: OriginalARQValues
}

type ReceiverFormContextProps = {
  receiverFormDataObj: ReceiverFormData
  setReceiverFormDataObj: Dispatch<SetStateAction<ReceiverFormData>>
  isReceiverDataLoading: boolean | undefined
  setIsReceiverDataLoading: Dispatch<SetStateAction<boolean>>
  receiverURL: string | undefined
  setReceiverURL: Dispatch<SetStateAction<string>>
  pictoFutureValue: number | undefined
  setPictoFutureValue: Dispatch<SetStateAction<number>>
  timeSeriesGraphDataObj: TimeSeriesBarGraphData[]
  setTimeSeriesGraphDataObj: Dispatch<SetStateAction<TimeSeriesBarGraphData[]>>
}

// default values
const initReceiverContextPropsState = {
  receiverFormDataObj: {
    starting_amount: 1000.0,
    contributions: 0,
    contributions_frequency: 12,
    // rate_of_return: 4.5,
    custom_investment_values: [
      {
        id: 0,
        type_rate_of_return: typesOfRoR[0].type,
        investment_name: '',
        investment_value: 0,
      },
    ],
    years_to_grow: 10,
    greetings: '',
    logo_url: 'logo_url',
    ARQ_Flip_Gain: 0,
    ARQ_rental_income: 100,
    ARQ_rental_contribution: 12,
    ARQ_appreciation: 0,
    ARQ_property_worth: 10000,
    management_fee: 80,
    selected_rate_of_return_obj: assets[defaultAsset],
    custom_email: '',
    selected_bars: [0, 1, 2, 3, 4, 7],
  },
  setReceiverFormDataObj: () => {},
  isReceiverDataLoading: true,
  setIsReceiverDataLoading: () => {},
  receiverURL: '',
  setReceiverURL: () => {},
  pictoFutureValue: 0,
  setPictoFutureValue: () => {},
  // time series bar graph obj
  timeSeriesGraphDataObj: [] as TimeSeriesBarGraphData[],
  setTimeSeriesGraphDataObj: () => {},
}

// default values when URL contains /arq/home
const initARQReceiverContextPropsState = {
  receiverFormDataObj: {
    starting_amount: 50000,
    contributions: 0,
    contributions_frequency: 12,
    // rate_of_return: 4.5,
    custom_investment_values: [
      {
        id: 0,
        type_rate_of_return: typesOfRoR[0].type,
        investment_name: '',
        investment_value: 0,
      },
    ],
    years_to_grow: 3,
    greetings: '',
    logo_url: 'logo_url',
    ARQ_Flip_Gain: 510000,
    ARQ_rental_income: 25500,
    ARQ_rental_contribution: 4,
    ARQ_appreciation: 0,
    ARQ_property_worth: 1275000,
    management_fee: 80,
    selected_rate_of_return_obj: assets[6],
    custom_email: '',
    selected_bars: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  setReceiverFormDataObj: () => {},
  isReceiverDataLoading: true,
  setIsReceiverDataLoading: () => {},
  receiverURL: '',
  setReceiverURL: () => {},
  pictoFutureValue: 0,
  setPictoFutureValue: () => {},
}

const ReceiverFormContext = createContext<ReceiverFormContextProps>(initReceiverContextPropsState)

const ReceiverFormProvider: React.FC<WithChildren> = React.memo(({children}) => {
  const location = window.location.pathname
  const pathsToCheck = [
    'arq/home',
    'advisor-demo1/home',
    'advisor-demo2/home',
    'waystone/home',
    'arq/create',
  ]
  const isArqInURL = pathsToCheck.some((path) => location.includes(path))

  const [receiverFormDataObj, setReceiverFormDataObj] = useState(
    isArqInURL
      ? initARQReceiverContextPropsState.receiverFormDataObj
      : initReceiverContextPropsState.receiverFormDataObj
  )
  const [isReceiverDataLoading, setIsReceiverDataLoading] = useState(true)
  const [receiverURL, setReceiverURL] = useState('')
  const [pictoFutureValue, setPictoFutureValue] = useState(0)
  const [timeSeriesGraphDataObj, setTimeSeriesGraphDataObj] = useState<TimeSeriesBarGraphData[]>(
    initReceiverContextPropsState.timeSeriesGraphDataObj
  )

  // This useMemo ensures that the context value only changes when any of the context properties change
  const contextValue = useMemo(
    () => ({
      receiverFormDataObj,
      setReceiverFormDataObj,
      isReceiverDataLoading,
      setIsReceiverDataLoading,
      receiverURL,
      setReceiverURL,
      pictoFutureValue,
      setPictoFutureValue,
      timeSeriesGraphDataObj,
      setTimeSeriesGraphDataObj,
    }),
    [
      receiverFormDataObj,
      isReceiverDataLoading,
      receiverURL,
      pictoFutureValue,
      timeSeriesGraphDataObj,
    ]
  )
  return (
    <ReceiverFormContext.Provider value={contextValue}>{children}</ReceiverFormContext.Provider>
  )
})

const useReceiverFormContext = () => {
  const context = useContext(ReceiverFormContext)
  if (!context) {
    throw new Error('useReceiverFormContext must be used within a ReceiverFormProvider')
  }
  return context
}

export {ReceiverFormProvider, useReceiverFormContext}
