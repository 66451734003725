import {useRef} from 'react'
import {motion, useTransform, useScroll} from 'framer-motion'
import {SAME_MATH_SECTION, sectionIds} from '../../../constants/AppConstants'

const SameMathNewLookSection = () => {
  const targetRef = useRef(null)
  const {scrollYProgress} = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })

  const wateringCanX = useTransform(scrollYProgress, [0, 0.36], ['100%', '0%'])
  const wateringCanY = useTransform(scrollYProgress, [0, 0.36], ['-100%', '0%'])
  const calcBirdX = useTransform(scrollYProgress, [0.2, 0.38], ['-100%', '0%'])
  const calcBirdY = useTransform(scrollYProgress, [0.2, 0.38], ['-60%', '0%'])
  const magGearsX = useTransform(scrollYProgress, [0.35, 0.5], ['100%', '0%'])
  const magGearsY = useTransform(scrollYProgress, [0.35, 0.5], ['100%', '0%'])

  const currentHostname = window.location.hostname

  return (
    <motion.section
      ref={targetRef}
      className='position-relative d-grid pt-md-20 py-8 px-7 px-md-10 px-lg-20'
      id={sectionIds[6]}
    >
      <h1 className='my-2 mb-6 text-center z-index-1'>{SAME_MATH_SECTION.SAME_MATH}</h1>
      <div className='row mt-2'>
        <motion.div
          className='col-lg-6 d-flex d-lg-none justify-content-center align-items-center'
          style={{x: wateringCanX, y: wateringCanY}}
        >
          <img
            alt='watering-can'
            className='image-container'
            src={SAME_MATH_SECTION.IMAGES.WATERING_CAN}
          />
        </motion.div>
        <div className='col-lg-6'>
          <h2 className='my-4 text-center'>{SAME_MATH_SECTION.POPULAR_INVESTMENTS}</h2>
          <p className='my-4 text-center z-index-1'>{SAME_MATH_SECTION.HELP}</p>
        </div>
        <motion.div
          className='col-lg-6 d-none d-lg-flex justify-content-center align-items-center'
          style={{x: wateringCanX, y: wateringCanY}}
        >
          <img
            alt='watering-can'
            className='image-container'
            src={SAME_MATH_SECTION.IMAGES.WATERING_CAN}
          />
        </motion.div>
      </div>
      <div className='row mt-2'>
        <motion.div
          className='col-lg-6 d-flex justify-content-center align-items-center'
          style={{x: calcBirdX, y: calcBirdY}}
        >
          <img
            alt='calc-bird'
            className='image-container'
            src={SAME_MATH_SECTION.IMAGES.CALC_BIRD}
          />
        </motion.div>
        <div className='col-lg-6'>
          <h2 className='my-4 text-center'>{SAME_MATH_SECTION.STANDARD_FORMULA}</h2>
          <p className='my-2 text-center pl-6 z-index-1'>{SAME_MATH_SECTION.FINANCIAL_FORMULA}</p>
        </div>
      </div>
      <div className='row mt-2'>
        <motion.div
          className='col-lg-6 d-flex d-lg-none justify-content-center align-items-center'
          style={{x: magGearsX, y: magGearsY}}
        >
          <img
            alt='mag-gears'
            className='image-container'
            src={SAME_MATH_SECTION.IMAGES.MAG_GEARS}
          />
        </motion.div>
        <div className='col-lg-6'>
          <h2 className='my-4 text-center'>{SAME_MATH_SECTION.SHOWING_RISK}</h2>
          <p className='my-4 text-center z-index-1'>
            {currentHostname === 'vividli.ai'
              ? SAME_MATH_SECTION.VIVIDLI_PREDICT
              : SAME_MATH_SECTION.PREDICT}
          </p>
        </div>
        <motion.div
          className='col-lg-6 d-none d-lg-flex justify-content-center align-items-center'
          style={{x: magGearsX, y: magGearsY}}
        >
          <img
            alt='mag-gears'
            className='image-container'
            src={SAME_MATH_SECTION.IMAGES.MAG_GEARS}
          />
        </motion.div>
      </div>
    </motion.section>
  )
}

export default SameMathNewLookSection
