import {Fragment, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {FEATURE_COMING_SOON, FINANCIAL_SECTION, FOOTER_DATA} from '../../../constants/AppConstants'
import VerticallyCenteredModal from '../../../../_metronic/layout/components/modal/VerticallyCenteredModal'

const FinanciallyFreeSection = () => {
  const navigate = useNavigate()

  const [showThankYouModal, setShowThankYouModal] = useState(false)
  const handleOnClickMail = () => {
    navigate('/contact')
  }

  return (
    <Fragment>
      <div className='position-relative px-7 px-md-10 mx-auto'>
        {/* footer */}
        <footer className='footer-container' id='footer'>
          <div className='row px-7 px-md-10 position-relative z-index-1'>
            <div className='col-lg-6 custom-opacity'>
              <h2>{FOOTER_DATA.JOIN_TEAM}</h2>
              <p className='paragraph-small mb-0 fw-300'>{FOOTER_DATA.MISSION}</p>
            </div>
            <div className='col-lg-6 d-flex flex-column flex-sm-row justify-content-between align-items-center mt-15 mt-lg-0'>
              <p className='custom-opacity contact-us-text mb-0 text-start text-lg-end w-100 me-lg-5 text-nowrap'>
                {FOOTER_DATA.CONTACT_US}
              </p>
              <div className='d-flex justify-content-between align-items-center mt-5 mt-sm-0'>
                <img
                  alt='Email'
                  className='mx-2 cursor-pointer'
                  src={FINANCIAL_SECTION.IMAGES.EMAIL_ICON}
                  onClick={handleOnClickMail}
                />
                <img
                  alt='Reddit'
                  className='mx-2 cursor-pointer'
                  src={FINANCIAL_SECTION.IMAGES.REDDIT_ICON}
                  onClick={() => setShowThankYouModal(true)}
                />
                <a href={FINANCIAL_SECTION.DISCORD_LINK} target='_blank' rel='noreferrer'>
                  <img
                    alt='Discord'
                    className='mx-2 cursor-pointer'
                    src={FINANCIAL_SECTION.IMAGES.DISCORD_ICON}
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
      {/* web view */}
      <div className='position-relative d-none d-lg-block px-md-10 pt-10 mx-auto'>
        <div className='legal-notes-container'>
          <p className='paragraph-small fw-400 px-13'>{FOOTER_DATA.LEGAL_NOTES_FIRST_TITLE}</p>
          <p className='paragraph-x-small px-13'>{FOOTER_DATA.ABOUT_MONOLITHS}</p>
        </div>
        <p className='paragraph-small fw-400 px-13 mt-4'>{FOOTER_DATA.LEGAL_NOTES_SECOND_TITLE}</p>
        <p className='paragraph-x-small px-13 position-relative z-index-1' style={{width: '93%'}}>
          {FOOTER_DATA.LEGAL_NOTES_PARAGRAPH}
        </p>
        <div className='d-flex align-items-center mb-2 position-relative z-index-1'>
          <p className='paragraph-small fw-400 ps-13 pe-1 mb-0'>{FOOTER_DATA.ONYXTEC_CREDIT}</p>
          <a href={process.env.REACT_APP_ONYXTEC_URL} target='_blank' rel='noreferrer'>
            {' '}
            {FOOTER_DATA.ONYXTEC}{' '}
            <img
              alt='onyxtec-logo'
              className='onyxtec-logo'
              src={FOOTER_DATA.IMAGES.ONYXTEC_LOGO}
            />
          </a>
        </div>
      </div>
      {/* mobile view */}
      <div className='d-block d-lg-none'>
        <div className='py-12 px-7 px-md-10 px-lg-20'>
          <p className='paragraph-small fw-bold'>{FOOTER_DATA.LEGAL_NOTES_FIRST_TITLE}</p>
          <p className='paragraph-x-small'>{FOOTER_DATA.ABOUT_MONOLITHS}</p>
          <br></br>
          <p className='paragraph-small fw-bold'>{FOOTER_DATA.LEGAL_NOTES_SECOND_TITLE}</p>
          <p className='paragraph-x-small'>{FOOTER_DATA.LEGAL_NOTES_PARAGRAPH}</p>
          <div className='d-flex align-items-center mb-2 position-relative z-index-1'>
            <p className='paragraph-small fw-400 pe-1 mb-0'>{FOOTER_DATA.ONYXTEC_CREDIT}</p>
            <a href={process.env.REACT_APP_ONYXTEC_URL} target='_blank' rel='noreferrer'>
              {' '}
              {FOOTER_DATA.ONYXTEC}{' '}
              <img
                alt='onyxtec-logo'
                className='onyxtec-logo'
                src={FOOTER_DATA.IMAGES.ONYXTEC_LOGO}
              />
            </a>
          </div>
        </div>
      </div>
      {/* modal for feature coming soon */}
      <VerticallyCenteredModal
        show={showThankYouModal}
        onHide={() => setShowThankYouModal(false)}
        title={FEATURE_COMING_SOON.TITLE}
        message={FEATURE_COMING_SOON.MESSAGE}
      />
    </Fragment>
  )
}

export default FinanciallyFreeSection
