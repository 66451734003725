import axios from 'axios'
import {CustomInvestments} from '../components/_model'
const POST_ADVISOR_FORM_URL = 'advisors-form/add-advise'
const FETCH_ADVISOR_FORM_URL = 'advisors-form/get-advise'
const UPDATE_ADVISOR_FORM_URL = 'advisors-form/update-advise'
const UPLOAD_IMAGE = 'advisors-form/upload-image'

// fetching advisor form data
export function uploadImageToServer(image: FormData) {
  return axios.post(UPLOAD_IMAGE, image)
}

// fetching advisor form data
export function fetchAdvisorFormData(URL: string) {
  return axios.get(FETCH_ADVISOR_FORM_URL, {
    params: {advise_url: URL},
  })
}

// storing advisor form data
export function submitAdvisorForm(
  starting_amount: number,
  contributions: number,
  contributions_frequency: number,
  rate_of_return: number,
  customFields: CustomInvestments[],
  years_to_grow: number,
  greetings: string,
  logo_url: string,
  custom_email: string,
  ARQ_Flip_Gain: number,
  ARQ_rental_income: number,
  ARQ_rental_contribution: number,
  ARQ_appreciation: number,
  ARQ_property_worth: number,
  notes: string | null,
  management_fee: number,
  redirectURLRoute: string | null,
  selected_bars: object | null
) {
  return axios.post(POST_ADVISOR_FORM_URL, {
    starting_amount: starting_amount,
    contributions: contributions,
    contributions_frequency: contributions_frequency,
    rate_of_return: rate_of_return,
    custom_investment_values: customFields,
    years_to_grow: years_to_grow,
    greeting_message: greetings,
    logo: logo_url,
    custom_email: custom_email,
    ARQ_Flip_Gain: ARQ_Flip_Gain,
    ARQ_rental_income: ARQ_rental_income,
    ARQ_rental_contribution: ARQ_rental_contribution,
    ARQ_appreciation: ARQ_appreciation,
    ARQ_property_worth: ARQ_property_worth,
    notes: notes,
    management_fee: management_fee,
    redirectURLRoute: redirectURLRoute,
    selected_bars: selected_bars,
  })
}

// updating advisor form data
export function updateAdvisorForm(
  starting_amount: number,
  contributions: number,
  contributions_frequency: number,
  rate_of_return: number,
  customFields: CustomInvestments[],
  years_to_grow: number,
  greetings: string,
  logo_url: string,
  custom_email: string,
  ARQ_Flip_Gain: number,
  ARQ_rental_income: number,
  ARQ_rental_contribution: number,
  ARQ_appreciation: number,
  ARQ_property_worth: number,
  URL: string | null | undefined,
  notes: string | null,
  management_fee: number,
  redirectURLRoute: string | null,
  selected_bars: object | null
) {
  return axios.put(UPDATE_ADVISOR_FORM_URL, {
    starting_amount: starting_amount,
    contributions: contributions,
    contributions_frequency: contributions_frequency,
    rate_of_return: rate_of_return,
    custom_investment_values: customFields,
    years_to_grow: years_to_grow,
    greeting_message: greetings,
    logo: logo_url,
    custom_email: custom_email,
    ARQ_Flip_Gain: ARQ_Flip_Gain,
    ARQ_rental_income: ARQ_rental_income,
    ARQ_rental_contribution: ARQ_rental_contribution,
    ARQ_appreciation: ARQ_appreciation,
    ARQ_property_worth: ARQ_property_worth,
    advise_url: URL,
    notes: notes,
    management_fee,
    redirectURLRoute: redirectURLRoute,
    selected_bars: selected_bars,
  })
}
