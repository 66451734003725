import {BEGINNERS_SECTION, sectionIds} from '../../../constants/AppConstants'

const BeginnersSection = () => {
  const currentHostname = window.location.hostname

  return (
    <section
      className='position-relative z-index-1 px-7 px-md-10 px-lg-20 pt-5 pt-lg-15'
      id={sectionIds[7]}
    >
      <h1 className='my-2 mb-6 text-center'> {BEGINNERS_SECTION.WE_DO} </h1>
      <p className='text-center'>{BEGINNERS_SECTION.VISUALIZATION}</p>
      <br></br>
      <div className='d-flex justify-content-center align-items-center'>
        <p className='text-center mission-text'>{BEGINNERS_SECTION.OUR_MISSION}</p>
      </div>

      <img
        className='cloud-big d-none d-md-block'
        src={BEGINNERS_SECTION.IMAGES.CLOUD_LARGE}
        alt='cloud-big'
      />
      <img className='cloud-small' src={BEGINNERS_SECTION.IMAGES.CLOUD_SMALL} alt='cloud-small' />
      <div className='row m-0 mt-7'>
        <div className='col-lg-5'>
          <p className='text-center text-xl-start'>
            {currentHostname === 'vividli.ai'
              ? BEGINNERS_SECTION.VIVIDLI_MONOLITH_HELP
              : BEGINNERS_SECTION.MONOLITH_HELP}
          </p>
        </div>
        {/* our values section desktop view */}
        <div className='d-none d-lg-block col-lg-7 our-values-container'>
          <h2 className='our-values-text position-absolute z-index-1'>
            {BEGINNERS_SECTION.OUR_VALUES}
          </h2>
          <img
            className='position-absolute watering-plants-img'
            src={BEGINNERS_SECTION.IMAGES.WATERING_PLANTS}
            alt='cloud-big'
          />
          <img
            className='position-absolute our-values-img'
            src={BEGINNERS_SECTION.IMAGES.VALUES_BAG}
            alt='cloud-big'
          />
        </div>
      </div>
      {/* our values section mobile view */}
      <div className='d-flex d-lg-none flex-column justify-content-center align-items-center'>
        <img
          className='watering-plants-img'
          src={BEGINNERS_SECTION.IMAGES.WATERING_PLANTS}
          alt='cloud-big'
        />
        <h2 className='our-values-text text-center mt-7'>{BEGINNERS_SECTION.OUR_VALUES}</h2>
        <img className='our-values-img' src={BEGINNERS_SECTION.IMAGES.VALUES_BAG} alt='cloud-big' />
      </div>
    </section>
  )
}

export default BeginnersSection
