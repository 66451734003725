import {Fragment, useRef} from 'react'
import MetaTags from '../../../Meta/MetaTags'
import HeroSection from '../../modules/homeComponents/HeroSection'
import FinanciallyFreeSection from '../../modules/homeComponents/FinanciallyFreeSection'
import {Route, Routes} from 'react-router-dom'

const HomePage = () => {
  return (
    <Routes>
      <Route path='/*' element={<HomepageLayout />} />
    </Routes>
  )
}

export {HomePage}

const HomepageLayout = () => {
  // stay tuned ref
  const stayTunedSectionRef = useRef<HTMLDivElement>(null)
  // projections ref
  const ProjectionsSectionRef = useRef<HTMLDivElement>(null)

  // handle click stay tuned section
  const handleClickStayTuned = () => {
    if (stayTunedSectionRef.current) {
      const scrollOptions: ScrollToOptions = {
        top: stayTunedSectionRef.current.offsetTop,
        behavior: 'smooth',
      }
      window.scrollTo(scrollOptions)
    }
  }

  // handle click projections section
  const handleClickProjections = () => {
    if (ProjectionsSectionRef.current) {
      const scrollOptions: ScrollToOptions = {
        top: ProjectionsSectionRef.current.offsetTop,
        behavior: 'smooth',
      }
      window.scrollTo(scrollOptions)
    }
  }

  return (
    <Fragment>
      <div className='container-xxl'>
        {/* meta tags for SEO */}
        <MetaTags URL={window.location.href} />

        <section className='container'>
          <HeroSection
            handleClickProjections={handleClickProjections}
            handleClickStayTuned={handleClickStayTuned}
          />
          <FinanciallyFreeSection />
        </section>
      </div>
    </Fragment>
  )
}
